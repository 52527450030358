var At = Object.defineProperty;
var ve = (r) => {
  throw TypeError(r);
};
var Ct = (r, e, t) => e in r ? At(r, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : r[e] = t;
var g = (r, e, t) => Ct(r, typeof e != "symbol" ? e + "" : e, t), kt = (r, e, t) => e.has(r) || ve("Cannot " + t);
var ye = (r, e, t) => e.has(r) ? ve("Cannot add the same private member more than once") : e instanceof WeakSet ? e.add(r) : e.set(r, t);
var V = (r, e, t) => (kt(r, e, "access private method"), t);
var Lt = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Fe(r) {
  return r && r.__esModule && Object.prototype.hasOwnProperty.call(r, "default") ? r.default : r;
}
var Pe = { exports: {} };
(function(r) {
  (function() {
    var e = this;
    (function() {
      (function() {
        this.Rails = {
          linkClickSelector: "a[data-confirm], a[data-method], a[data-remote]:not([disabled]), a[data-disable-with], a[data-disable]",
          buttonClickSelector: {
            selector: "button[data-remote]:not([form]), button[data-confirm]:not([form])",
            exclude: "form button"
          },
          inputChangeSelector: "select[data-remote], input[data-remote], textarea[data-remote]",
          formSubmitSelector: "form",
          formInputClickSelector: "form input[type=submit], form input[type=image], form button[type=submit], form button:not([type]), input[type=submit][form], input[type=image][form], button[type=submit][form], button[form]:not([type])",
          formDisableSelector: "input[data-disable-with]:enabled, button[data-disable-with]:enabled, textarea[data-disable-with]:enabled, input[data-disable]:enabled, button[data-disable]:enabled, textarea[data-disable]:enabled",
          formEnableSelector: "input[data-disable-with]:disabled, button[data-disable-with]:disabled, textarea[data-disable-with]:disabled, input[data-disable]:disabled, button[data-disable]:disabled, textarea[data-disable]:disabled",
          fileInputSelector: "input[name][type=file]:not([disabled])",
          linkDisableSelector: "a[data-disable-with], a[data-disable]",
          buttonDisableSelector: "button[data-remote][data-disable-with], button[data-remote][data-disable]"
        };
      }).call(this);
    }).call(e);
    var t = e.Rails;
    (function() {
      (function() {
        var s, i;
        i = Element.prototype.matches || Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector, t.matches = function(n, o) {
          return o.exclude != null ? i.call(n, o.selector) && !i.call(n, o.exclude) : i.call(n, o);
        }, s = "_ujsData", t.getData = function(n, o) {
          var c;
          return (c = n[s]) != null ? c[o] : void 0;
        }, t.setData = function(n, o, c) {
          return n[s] == null && (n[s] = {}), n[s][o] = c;
        }, t.$ = function(n) {
          return Array.prototype.slice.call(document.querySelectorAll(n));
        };
      }).call(this), (function() {
        var s, i, n;
        s = t.$, n = t.csrfToken = function() {
          var o;
          return o = document.querySelector("meta[name=csrf-token]"), o && o.content;
        }, i = t.csrfParam = function() {
          var o;
          return o = document.querySelector("meta[name=csrf-param]"), o && o.content;
        }, t.CSRFProtection = function(o) {
          var c;
          if (c = n(), c != null)
            return o.setRequestHeader("X-CSRF-Token", c);
        }, t.refreshCSRFTokens = function() {
          var o, c;
          if (c = n(), o = i(), c != null && o != null)
            return s('form input[name="' + o + '"]').forEach(function(a) {
              return a.value = c;
            });
        };
      }).call(this), (function() {
        var s, i, n;
        n = t.matches, s = window.CustomEvent, typeof s != "function" && (s = function(o, c) {
          var a;
          return a = document.createEvent("CustomEvent"), a.initCustomEvent(o, c.bubbles, c.cancelable, c.detail), a;
        }, s.prototype = window.Event.prototype), i = t.fire = function(o, c, a) {
          var l;
          return l = new s(c, {
            bubbles: !0,
            cancelable: !0,
            detail: a
          }), o.dispatchEvent(l), !l.defaultPrevented;
        }, t.stopEverything = function(o) {
          return i(o.target, "ujs:everythingStopped"), o.preventDefault(), o.stopPropagation(), o.stopImmediatePropagation();
        }, t.delegate = function(o, c, a, l) {
          return o.addEventListener(a, function(d) {
            var p;
            for (p = d.target; !(!(p instanceof Element) || n(p, c)); )
              p = p.parentNode;
            if (p instanceof Element && l.call(p, d) === !1)
              return d.preventDefault(), d.stopPropagation();
          });
        };
      }).call(this), (function() {
        var s, i, n, o, c;
        i = t.CSRFProtection, t.fire, s = {
          "*": "*/*",
          text: "text/plain",
          html: "text/html",
          xml: "application/xml, text/xml",
          json: "application/json, text/javascript",
          script: "text/javascript, application/javascript, application/ecmascript, application/x-ecmascript"
        }, t.ajax = function(a) {
          var l;
          if (a = o(a), l = n(a, function() {
            var d;
            return d = c(l.response, l.getResponseHeader("Content-Type")), Math.floor(l.status / 100) === 2 ? typeof a.success == "function" && a.success(d, l.statusText, l) : typeof a.error == "function" && a.error(d, l.statusText, l), typeof a.complete == "function" ? a.complete(l, l.statusText) : void 0;
          }), !(typeof a.beforeSend == "function" && a.beforeSend(l, a)))
            return !1;
          if (l.readyState === XMLHttpRequest.OPENED)
            return l.send(a.data);
        }, o = function(a) {
          return a.url = a.url || location.href, a.type = a.type.toUpperCase(), a.type === "GET" && a.data && (a.url.indexOf("?") < 0 ? a.url += "?" + a.data : a.url += "&" + a.data), s[a.dataType] == null && (a.dataType = "*"), a.accept = s[a.dataType], a.dataType !== "*" && (a.accept += ", */*; q=0.01"), a;
        }, n = function(a, l) {
          var d;
          return d = new XMLHttpRequest(), d.open(a.type, a.url, !0), d.setRequestHeader("Accept", a.accept), typeof a.data == "string" && d.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8"), a.crossDomain || d.setRequestHeader("X-Requested-With", "XMLHttpRequest"), i(d), d.withCredentials = !!a.withCredentials, d.onreadystatechange = function() {
            if (d.readyState === XMLHttpRequest.DONE)
              return l(d);
          }, d;
        }, c = function(a, l) {
          var d, p;
          if (typeof a == "string" && typeof l == "string") {
            if (l.match(/\bjson\b/))
              try {
                a = JSON.parse(a);
              } catch {
              }
            else if (l.match(/\b(?:java|ecma)script\b/))
              p = document.createElement("script"), p.text = a, document.head.appendChild(p).parentNode.removeChild(p);
            else if (l.match(/\b(xml|html|svg)\b/)) {
              d = new DOMParser(), l = l.replace(/;.+/, "");
              try {
                a = d.parseFromString(a, l);
              } catch {
              }
            }
          }
          return a;
        }, t.href = function(a) {
          return a.href;
        }, t.isCrossDomain = function(a) {
          var l, d;
          l = document.createElement("a"), l.href = location.href, d = document.createElement("a");
          try {
            return d.href = a, !((!d.protocol || d.protocol === ":") && !d.host || l.protocol + "//" + l.host == d.protocol + "//" + d.host);
          } catch {
            return !0;
          }
        };
      }).call(this), (function() {
        var s, i;
        s = t.matches, i = function(n) {
          return Array.prototype.slice.call(n);
        }, t.serializeElement = function(n, o) {
          var c, a;
          return c = [n], s(n, "form") && (c = i(n.elements)), a = [], c.forEach(function(l) {
            if (!(!l.name || l.disabled)) {
              if (s(l, "select"))
                return i(l.options).forEach(function(d) {
                  if (d.selected)
                    return a.push({
                      name: l.name,
                      value: d.value
                    });
                });
              if (l.checked || ["radio", "checkbox", "submit"].indexOf(l.type) === -1)
                return a.push({
                  name: l.name,
                  value: l.value
                });
            }
          }), o && a.push(o), a.map(function(l) {
            return l.name != null ? encodeURIComponent(l.name) + "=" + encodeURIComponent(l.value) : l;
          }).join("&");
        }, t.formElements = function(n, o) {
          return s(n, "form") ? i(n.elements).filter(function(c) {
            return s(c, o);
          }) : i(n.querySelectorAll(o));
        };
      }).call(this), (function() {
        var s, i, n;
        i = t.fire, n = t.stopEverything, t.handleConfirm = function(o) {
          if (!s(this))
            return n(o);
        }, s = function(o) {
          var c, a, l;
          if (l = o.getAttribute("data-confirm"), !l)
            return !0;
          if (c = !1, i(o, "confirm")) {
            try {
              c = confirm(l);
            } catch {
            }
            a = i(o, "confirm:complete", [c]);
          }
          return c && a;
        };
      }).call(this), (function() {
        var s, i, n, o, c, a, l, d, p, b, y;
        p = t.matches, d = t.getData, b = t.setData, y = t.stopEverything, l = t.formElements, t.handleDisabledElement = function(h) {
          var u;
          if (u = this, u.disabled)
            return y(h);
        }, t.enableElement = function(h) {
          var u;
          if (u = h instanceof Event ? h.target : h, p(u, t.linkDisableSelector))
            return a(u);
          if (p(u, t.buttonDisableSelector) || p(u, t.formEnableSelector))
            return o(u);
          if (p(u, t.formSubmitSelector))
            return c(u);
        }, t.disableElement = function(h) {
          var u;
          if (u = h instanceof Event ? h.target : h, p(u, t.linkDisableSelector))
            return n(u);
          if (p(u, t.buttonDisableSelector) || p(u, t.formDisableSelector))
            return s(u);
          if (p(u, t.formSubmitSelector))
            return i(u);
        }, n = function(h) {
          var u;
          return u = h.getAttribute("data-disable-with"), u != null && (b(h, "ujs:enable-with", h.innerHTML), h.innerHTML = u), h.addEventListener("click", y), b(h, "ujs:disabled", !0);
        }, a = function(h) {
          var u;
          return u = d(h, "ujs:enable-with"), u != null && (h.innerHTML = u, b(h, "ujs:enable-with", null)), h.removeEventListener("click", y), b(h, "ujs:disabled", null);
        }, i = function(h) {
          return l(h, t.formDisableSelector).forEach(s);
        }, s = function(h) {
          var u;
          return u = h.getAttribute("data-disable-with"), u != null && (p(h, "button") ? (b(h, "ujs:enable-with", h.innerHTML), h.innerHTML = u) : (b(h, "ujs:enable-with", h.value), h.value = u)), h.disabled = !0, b(h, "ujs:disabled", !0);
        }, c = function(h) {
          return l(h, t.formEnableSelector).forEach(o);
        }, o = function(h) {
          var u;
          return u = d(h, "ujs:enable-with"), u != null && (p(h, "button") ? h.innerHTML = u : h.value = u, b(h, "ujs:enable-with", null)), h.disabled = !1, b(h, "ujs:disabled", null);
        };
      }).call(this), (function() {
        var s;
        s = t.stopEverything, t.handleMethod = function(i) {
          var n, o, c, a, l, d, p;
          if (d = this, p = d.getAttribute("data-method"), !!p)
            return l = t.href(d), o = t.csrfToken(), n = t.csrfParam(), c = document.createElement("form"), a = "<input name='_method' value='" + p + "' type='hidden' />", n != null && o != null && !t.isCrossDomain(l) && (a += "<input name='" + n + "' value='" + o + "' type='hidden' />"), a += '<input type="submit" />', c.method = "post", c.action = l, c.target = d.target, c.innerHTML = a, c.style.display = "none", document.body.appendChild(c), c.querySelector('[type="submit"]').click(), s(i);
        };
      }).call(this), (function() {
        var s, i, n, o, c, a, l, d, p, b = [].slice;
        a = t.matches, n = t.getData, d = t.setData, i = t.fire, p = t.stopEverything, s = t.ajax, o = t.isCrossDomain, l = t.serializeElement, c = function(y) {
          var h;
          return h = y.getAttribute("data-remote"), h != null && h !== "false";
        }, t.handleRemote = function(y) {
          var h, u, O, m, j, L, se;
          return m = this, c(m) ? i(m, "ajax:before") ? (se = m.getAttribute("data-with-credentials"), O = m.getAttribute("data-type") || "script", a(m, t.formSubmitSelector) ? (h = n(m, "ujs:submit-button"), j = n(m, "ujs:submit-button-formmethod") || m.method, L = n(m, "ujs:submit-button-formaction") || m.getAttribute("action") || location.href, j.toUpperCase() === "GET" && (L = L.replace(/\?.*$/, "")), m.enctype === "multipart/form-data" ? (u = new FormData(m), h != null && u.append(h.name, h.value)) : u = l(m, h), d(m, "ujs:submit-button", null), d(m, "ujs:submit-button-formmethod", null), d(m, "ujs:submit-button-formaction", null)) : a(m, t.buttonClickSelector) || a(m, t.inputChangeSelector) ? (j = m.getAttribute("data-method"), L = m.getAttribute("data-url"), u = l(m, m.getAttribute("data-params"))) : (j = m.getAttribute("data-method"), L = t.href(m), u = m.getAttribute("data-params")), s({
            type: j || "GET",
            url: L,
            data: u,
            dataType: O,
            beforeSend: function(C, Tt) {
              return i(m, "ajax:beforeSend", [C, Tt]) ? i(m, "ajax:send", [C]) : (i(m, "ajax:stopped"), !1);
            },
            success: function() {
              var C;
              return C = 1 <= arguments.length ? b.call(arguments, 0) : [], i(m, "ajax:success", C);
            },
            error: function() {
              var C;
              return C = 1 <= arguments.length ? b.call(arguments, 0) : [], i(m, "ajax:error", C);
            },
            complete: function() {
              var C;
              return C = 1 <= arguments.length ? b.call(arguments, 0) : [], i(m, "ajax:complete", C);
            },
            crossDomain: o(L),
            withCredentials: se != null && se !== "false"
          }), p(y)) : (i(m, "ajax:stopped"), !1) : !0;
        }, t.formSubmitButtonClick = function(y) {
          var h, u;
          if (h = this, u = h.form, !!u)
            return h.name && d(u, "ujs:submit-button", {
              name: h.name,
              value: h.value
            }), d(u, "ujs:formnovalidate-button", h.formNoValidate), d(u, "ujs:submit-button-formaction", h.getAttribute("formaction")), d(u, "ujs:submit-button-formmethod", h.getAttribute("formmethod"));
        }, t.handleMetaClick = function(y) {
          var h, u, O, m;
          if (u = this, m = (u.getAttribute("data-method") || "GET").toUpperCase(), h = u.getAttribute("data-params"), O = y.metaKey || y.ctrlKey, O && m === "GET" && !h)
            return y.stopImmediatePropagation();
        };
      }).call(this), (function() {
        var s, i, n, o, c, a, l, d, p, b, y, h, u, O;
        a = t.fire, n = t.delegate, d = t.getData, s = t.$, O = t.refreshCSRFTokens, i = t.CSRFProtection, c = t.enableElement, o = t.disableElement, b = t.handleDisabledElement, p = t.handleConfirm, u = t.handleRemote, l = t.formSubmitButtonClick, y = t.handleMetaClick, h = t.handleMethod, typeof jQuery < "u" && jQuery !== null && jQuery.ajax != null && !jQuery.rails && (jQuery.rails = t, jQuery.ajaxPrefilter(function(m, j, L) {
          if (!m.crossDomain)
            return i(L);
        })), t.start = function() {
          if (window._rails_loaded)
            throw new Error("rails-ujs has already been loaded!");
          return window.addEventListener("pageshow", function() {
            return s(t.formEnableSelector).forEach(function(m) {
              if (d(m, "ujs:disabled"))
                return c(m);
            }), s(t.linkDisableSelector).forEach(function(m) {
              if (d(m, "ujs:disabled"))
                return c(m);
            });
          }), n(document, t.linkDisableSelector, "ajax:complete", c), n(document, t.linkDisableSelector, "ajax:stopped", c), n(document, t.buttonDisableSelector, "ajax:complete", c), n(document, t.buttonDisableSelector, "ajax:stopped", c), n(document, t.linkClickSelector, "click", b), n(document, t.linkClickSelector, "click", p), n(document, t.linkClickSelector, "click", y), n(document, t.linkClickSelector, "click", o), n(document, t.linkClickSelector, "click", u), n(document, t.linkClickSelector, "click", h), n(document, t.buttonClickSelector, "click", b), n(document, t.buttonClickSelector, "click", p), n(document, t.buttonClickSelector, "click", o), n(document, t.buttonClickSelector, "click", u), n(document, t.inputChangeSelector, "change", b), n(document, t.inputChangeSelector, "change", p), n(document, t.inputChangeSelector, "change", u), n(document, t.formSubmitSelector, "submit", b), n(document, t.formSubmitSelector, "submit", p), n(document, t.formSubmitSelector, "submit", u), n(document, t.formSubmitSelector, "submit", function(m) {
            return setTimeout(function() {
              return o(m);
            }, 13);
          }), n(document, t.formSubmitSelector, "ajax:send", o), n(document, t.formSubmitSelector, "ajax:complete", c), n(document, t.formInputClickSelector, "click", b), n(document, t.formInputClickSelector, "click", p), n(document, t.formInputClickSelector, "click", l), document.addEventListener("DOMContentLoaded", O), window._rails_loaded = !0;
        }, window.Rails === t && a(document, "rails:attachBindings") && t.start();
      }).call(this);
    }).call(this), r.exports && (r.exports = t);
  }).call(Lt);
})(Pe);
var Mt = Pe.exports;
const Rt = /* @__PURE__ */ Fe(Mt);
class Ot {
  constructor(e, t, s) {
    this.eventTarget = e, this.eventName = t, this.eventOptions = s, this.unorderedBindings = /* @__PURE__ */ new Set();
  }
  connect() {
    this.eventTarget.addEventListener(this.eventName, this, this.eventOptions);
  }
  disconnect() {
    this.eventTarget.removeEventListener(this.eventName, this, this.eventOptions);
  }
  bindingConnected(e) {
    this.unorderedBindings.add(e);
  }
  bindingDisconnected(e) {
    this.unorderedBindings.delete(e);
  }
  handleEvent(e) {
    const t = Ft(e);
    for (const s of this.bindings) {
      if (t.immediatePropagationStopped)
        break;
      s.handleEvent(t);
    }
  }
  hasBindings() {
    return this.unorderedBindings.size > 0;
  }
  get bindings() {
    return Array.from(this.unorderedBindings).sort((e, t) => {
      const s = e.index, i = t.index;
      return s < i ? -1 : s > i ? 1 : 0;
    });
  }
}
function Ft(r) {
  if ("immediatePropagationStopped" in r)
    return r;
  {
    const { stopImmediatePropagation: e } = r;
    return Object.assign(r, {
      immediatePropagationStopped: !1,
      stopImmediatePropagation() {
        this.immediatePropagationStopped = !0, e.call(this);
      }
    });
  }
}
class Pt {
  constructor(e) {
    this.application = e, this.eventListenerMaps = /* @__PURE__ */ new Map(), this.started = !1;
  }
  start() {
    this.started || (this.started = !0, this.eventListeners.forEach((e) => e.connect()));
  }
  stop() {
    this.started && (this.started = !1, this.eventListeners.forEach((e) => e.disconnect()));
  }
  get eventListeners() {
    return Array.from(this.eventListenerMaps.values()).reduce((e, t) => e.concat(Array.from(t.values())), []);
  }
  bindingConnected(e) {
    this.fetchEventListenerForBinding(e).bindingConnected(e);
  }
  bindingDisconnected(e, t = !1) {
    this.fetchEventListenerForBinding(e).bindingDisconnected(e), t && this.clearEventListenersForBinding(e);
  }
  handleError(e, t, s = {}) {
    this.application.handleError(e, `Error ${t}`, s);
  }
  clearEventListenersForBinding(e) {
    const t = this.fetchEventListenerForBinding(e);
    t.hasBindings() || (t.disconnect(), this.removeMappedEventListenerFor(e));
  }
  removeMappedEventListenerFor(e) {
    const { eventTarget: t, eventName: s, eventOptions: i } = e, n = this.fetchEventListenerMapForEventTarget(t), o = this.cacheKey(s, i);
    n.delete(o), n.size == 0 && this.eventListenerMaps.delete(t);
  }
  fetchEventListenerForBinding(e) {
    const { eventTarget: t, eventName: s, eventOptions: i } = e;
    return this.fetchEventListener(t, s, i);
  }
  fetchEventListener(e, t, s) {
    const i = this.fetchEventListenerMapForEventTarget(e), n = this.cacheKey(t, s);
    let o = i.get(n);
    return o || (o = this.createEventListener(e, t, s), i.set(n, o)), o;
  }
  createEventListener(e, t, s) {
    const i = new Ot(e, t, s);
    return this.started && i.connect(), i;
  }
  fetchEventListenerMapForEventTarget(e) {
    let t = this.eventListenerMaps.get(e);
    return t || (t = /* @__PURE__ */ new Map(), this.eventListenerMaps.set(e, t)), t;
  }
  cacheKey(e, t) {
    const s = [e];
    return Object.keys(t).sort().forEach((i) => {
      s.push(`${t[i] ? "" : "!"}${i}`);
    }), s.join(":");
  }
}
const xt = {
  stop({ event: r, value: e }) {
    return e && r.stopPropagation(), !0;
  },
  prevent({ event: r, value: e }) {
    return e && r.preventDefault(), !0;
  },
  self({ event: r, value: e, element: t }) {
    return e ? t === r.target : !0;
  }
}, It = /^(?:(.+?)(?:\.(.+?))?(?:@(window|document))?->)?(.+?)(?:#([^:]+?))(?::(.+))?$/;
function Bt(r) {
  const t = r.trim().match(It) || [];
  let s = t[1], i = t[2];
  return i && !["keydown", "keyup", "keypress"].includes(s) && (s += `.${i}`, i = ""), {
    eventTarget: Dt(t[3]),
    eventName: s,
    eventOptions: t[6] ? Nt(t[6]) : {},
    identifier: t[4],
    methodName: t[5],
    keyFilter: i
  };
}
function Dt(r) {
  if (r == "window")
    return window;
  if (r == "document")
    return document;
}
function Nt(r) {
  return r.split(":").reduce((e, t) => Object.assign(e, { [t.replace(/^!/, "")]: !/^!/.test(t) }), {});
}
function qt(r) {
  if (r == window)
    return "window";
  if (r == document)
    return "document";
}
function ue(r) {
  return r.replace(/(?:[_-])([a-z0-9])/g, (e, t) => t.toUpperCase());
}
function re(r) {
  return ue(r.replace(/--/g, "-").replace(/__/g, "_"));
}
function z(r) {
  return r.charAt(0).toUpperCase() + r.slice(1);
}
function xe(r) {
  return r.replace(/([A-Z])/g, (e, t) => `-${t.toLowerCase()}`);
}
function Ht(r) {
  return r.match(/[^\s]+/g) || [];
}
class jt {
  constructor(e, t, s, i) {
    this.element = e, this.index = t, this.eventTarget = s.eventTarget || e, this.eventName = s.eventName || $t(e) || G("missing event name"), this.eventOptions = s.eventOptions || {}, this.identifier = s.identifier || G("missing identifier"), this.methodName = s.methodName || G("missing method name"), this.keyFilter = s.keyFilter || "", this.schema = i;
  }
  static forToken(e, t) {
    return new this(e.element, e.index, Bt(e.content), t);
  }
  toString() {
    const e = this.keyFilter ? `.${this.keyFilter}` : "", t = this.eventTargetName ? `@${this.eventTargetName}` : "";
    return `${this.eventName}${e}${t}->${this.identifier}#${this.methodName}`;
  }
  isFilterTarget(e) {
    if (!this.keyFilter)
      return !1;
    const t = this.keyFilter.split("+"), s = ["meta", "ctrl", "alt", "shift"], [i, n, o, c] = s.map((l) => t.includes(l));
    if (e.metaKey !== i || e.ctrlKey !== n || e.altKey !== o || e.shiftKey !== c)
      return !0;
    const a = t.filter((l) => !s.includes(l))[0];
    return a ? (Object.prototype.hasOwnProperty.call(this.keyMappings, a) || G(`contains unknown key filter: ${this.keyFilter}`), this.keyMappings[a].toLowerCase() !== e.key.toLowerCase()) : !1;
  }
  get params() {
    const e = {}, t = new RegExp(`^data-${this.identifier}-(.+)-param$`, "i");
    for (const { name: s, value: i } of Array.from(this.element.attributes)) {
      const n = s.match(t), o = n && n[1];
      o && (e[ue(o)] = Vt(i));
    }
    return e;
  }
  get eventTargetName() {
    return qt(this.eventTarget);
  }
  get keyMappings() {
    return this.schema.keyMappings;
  }
}
const we = {
  a: () => "click",
  button: () => "click",
  form: () => "submit",
  details: () => "toggle",
  input: (r) => r.getAttribute("type") == "submit" ? "click" : "input",
  select: () => "change",
  textarea: () => "input"
};
function $t(r) {
  const e = r.tagName.toLowerCase();
  if (e in we)
    return we[e](r);
}
function G(r) {
  throw new Error(r);
}
function Vt(r) {
  try {
    return JSON.parse(r);
  } catch {
    return r;
  }
}
class Ut {
  constructor(e, t) {
    this.context = e, this.action = t;
  }
  get index() {
    return this.action.index;
  }
  get eventTarget() {
    return this.action.eventTarget;
  }
  get eventOptions() {
    return this.action.eventOptions;
  }
  get identifier() {
    return this.context.identifier;
  }
  handleEvent(e) {
    this.willBeInvokedByEvent(e) && this.applyEventModifiers(e) && this.invokeWithEvent(e);
  }
  get eventName() {
    return this.action.eventName;
  }
  get method() {
    const e = this.controller[this.methodName];
    if (typeof e == "function")
      return e;
    throw new Error(`Action "${this.action}" references undefined method "${this.methodName}"`);
  }
  applyEventModifiers(e) {
    const { element: t } = this.action, { actionDescriptorFilters: s } = this.context.application;
    let i = !0;
    for (const [n, o] of Object.entries(this.eventOptions))
      if (n in s) {
        const c = s[n];
        i = i && c({ name: n, value: o, event: e, element: t });
      } else
        continue;
    return i;
  }
  invokeWithEvent(e) {
    const { target: t, currentTarget: s } = e;
    try {
      const { params: i } = this.action, n = Object.assign(e, { params: i });
      this.method.call(this.controller, n), this.context.logDebugActivity(this.methodName, { event: e, target: t, currentTarget: s, action: this.methodName });
    } catch (i) {
      const { identifier: n, controller: o, element: c, index: a } = this, l = { identifier: n, controller: o, element: c, index: a, event: e };
      this.context.handleError(i, `invoking action "${this.action}"`, l);
    }
  }
  willBeInvokedByEvent(e) {
    const t = e.target;
    return e instanceof KeyboardEvent && this.action.isFilterTarget(e) ? !1 : this.element === t ? !0 : t instanceof Element && this.element.contains(t) ? this.scope.containsElement(t) : this.scope.containsElement(this.action.element);
  }
  get controller() {
    return this.context.controller;
  }
  get methodName() {
    return this.action.methodName;
  }
  get element() {
    return this.scope.element;
  }
  get scope() {
    return this.context.scope;
  }
}
class Ie {
  constructor(e, t) {
    this.mutationObserverInit = { attributes: !0, childList: !0, subtree: !0 }, this.element = e, this.started = !1, this.delegate = t, this.elements = /* @__PURE__ */ new Set(), this.mutationObserver = new MutationObserver((s) => this.processMutations(s));
  }
  start() {
    this.started || (this.started = !0, this.mutationObserver.observe(this.element, this.mutationObserverInit), this.refresh());
  }
  pause(e) {
    this.started && (this.mutationObserver.disconnect(), this.started = !1), e(), this.started || (this.mutationObserver.observe(this.element, this.mutationObserverInit), this.started = !0);
  }
  stop() {
    this.started && (this.mutationObserver.takeRecords(), this.mutationObserver.disconnect(), this.started = !1);
  }
  refresh() {
    if (this.started) {
      const e = new Set(this.matchElementsInTree());
      for (const t of Array.from(this.elements))
        e.has(t) || this.removeElement(t);
      for (const t of Array.from(e))
        this.addElement(t);
    }
  }
  processMutations(e) {
    if (this.started)
      for (const t of e)
        this.processMutation(t);
  }
  processMutation(e) {
    e.type == "attributes" ? this.processAttributeChange(e.target, e.attributeName) : e.type == "childList" && (this.processRemovedNodes(e.removedNodes), this.processAddedNodes(e.addedNodes));
  }
  processAttributeChange(e, t) {
    const s = e;
    this.elements.has(s) ? this.delegate.elementAttributeChanged && this.matchElement(s) ? this.delegate.elementAttributeChanged(s, t) : this.removeElement(s) : this.matchElement(s) && this.addElement(s);
  }
  processRemovedNodes(e) {
    for (const t of Array.from(e)) {
      const s = this.elementFromNode(t);
      s && this.processTree(s, this.removeElement);
    }
  }
  processAddedNodes(e) {
    for (const t of Array.from(e)) {
      const s = this.elementFromNode(t);
      s && this.elementIsActive(s) && this.processTree(s, this.addElement);
    }
  }
  matchElement(e) {
    return this.delegate.matchElement(e);
  }
  matchElementsInTree(e = this.element) {
    return this.delegate.matchElementsInTree(e);
  }
  processTree(e, t) {
    for (const s of this.matchElementsInTree(e))
      t.call(this, s);
  }
  elementFromNode(e) {
    if (e.nodeType == Node.ELEMENT_NODE)
      return e;
  }
  elementIsActive(e) {
    return e.isConnected != this.element.isConnected ? !1 : this.element.contains(e);
  }
  addElement(e) {
    this.elements.has(e) || this.elementIsActive(e) && (this.elements.add(e), this.delegate.elementMatched && this.delegate.elementMatched(e));
  }
  removeElement(e) {
    this.elements.has(e) && (this.elements.delete(e), this.delegate.elementUnmatched && this.delegate.elementUnmatched(e));
  }
}
class _t {
  constructor(e, t, s) {
    this.attributeName = t, this.delegate = s, this.elementObserver = new Ie(e, this);
  }
  get element() {
    return this.elementObserver.element;
  }
  get selector() {
    return `[${this.attributeName}]`;
  }
  start() {
    this.elementObserver.start();
  }
  pause(e) {
    this.elementObserver.pause(e);
  }
  stop() {
    this.elementObserver.stop();
  }
  refresh() {
    this.elementObserver.refresh();
  }
  get started() {
    return this.elementObserver.started;
  }
  matchElement(e) {
    return e.hasAttribute(this.attributeName);
  }
  matchElementsInTree(e) {
    const t = this.matchElement(e) ? [e] : [], s = Array.from(e.querySelectorAll(this.selector));
    return t.concat(s);
  }
  elementMatched(e) {
    this.delegate.elementMatchedAttribute && this.delegate.elementMatchedAttribute(e, this.attributeName);
  }
  elementUnmatched(e) {
    this.delegate.elementUnmatchedAttribute && this.delegate.elementUnmatchedAttribute(e, this.attributeName);
  }
  elementAttributeChanged(e, t) {
    this.delegate.elementAttributeValueChanged && this.attributeName == t && this.delegate.elementAttributeValueChanged(e, t);
  }
}
function Wt(r, e, t) {
  Be(r, e).add(t);
}
function Kt(r, e, t) {
  Be(r, e).delete(t), zt(r, e);
}
function Be(r, e) {
  let t = r.get(e);
  return t || (t = /* @__PURE__ */ new Set(), r.set(e, t)), t;
}
function zt(r, e) {
  const t = r.get(e);
  t != null && t.size == 0 && r.delete(e);
}
class q {
  constructor() {
    this.valuesByKey = /* @__PURE__ */ new Map();
  }
  get keys() {
    return Array.from(this.valuesByKey.keys());
  }
  get values() {
    return Array.from(this.valuesByKey.values()).reduce((t, s) => t.concat(Array.from(s)), []);
  }
  get size() {
    return Array.from(this.valuesByKey.values()).reduce((t, s) => t + s.size, 0);
  }
  add(e, t) {
    Wt(this.valuesByKey, e, t);
  }
  delete(e, t) {
    Kt(this.valuesByKey, e, t);
  }
  has(e, t) {
    const s = this.valuesByKey.get(e);
    return s != null && s.has(t);
  }
  hasKey(e) {
    return this.valuesByKey.has(e);
  }
  hasValue(e) {
    return Array.from(this.valuesByKey.values()).some((s) => s.has(e));
  }
  getValuesForKey(e) {
    const t = this.valuesByKey.get(e);
    return t ? Array.from(t) : [];
  }
  getKeysForValue(e) {
    return Array.from(this.valuesByKey).filter(([t, s]) => s.has(e)).map(([t, s]) => t);
  }
}
class Jt {
  constructor(e, t, s, i = {}) {
    this.selector = t, this.details = i, this.elementObserver = new Ie(e, this), this.delegate = s, this.matchesByElement = new q();
  }
  get started() {
    return this.elementObserver.started;
  }
  start() {
    this.elementObserver.start();
  }
  pause(e) {
    this.elementObserver.pause(e);
  }
  stop() {
    this.elementObserver.stop();
  }
  refresh() {
    this.elementObserver.refresh();
  }
  get element() {
    return this.elementObserver.element;
  }
  matchElement(e) {
    const t = e.matches(this.selector);
    return this.delegate.selectorMatchElement ? t && this.delegate.selectorMatchElement(e, this.details) : t;
  }
  matchElementsInTree(e) {
    const t = this.matchElement(e) ? [e] : [], s = Array.from(e.querySelectorAll(this.selector)).filter((i) => this.matchElement(i));
    return t.concat(s);
  }
  elementMatched(e) {
    this.selectorMatched(e);
  }
  elementUnmatched(e) {
    this.selectorUnmatched(e);
  }
  elementAttributeChanged(e, t) {
    const s = this.matchElement(e), i = this.matchesByElement.has(this.selector, e);
    !s && i && this.selectorUnmatched(e);
  }
  selectorMatched(e) {
    this.delegate.selectorMatched && (this.delegate.selectorMatched(e, this.selector, this.details), this.matchesByElement.add(this.selector, e));
  }
  selectorUnmatched(e) {
    this.delegate.selectorUnmatched(e, this.selector, this.details), this.matchesByElement.delete(this.selector, e);
  }
}
class Xt {
  constructor(e, t) {
    this.element = e, this.delegate = t, this.started = !1, this.stringMap = /* @__PURE__ */ new Map(), this.mutationObserver = new MutationObserver((s) => this.processMutations(s));
  }
  start() {
    this.started || (this.started = !0, this.mutationObserver.observe(this.element, { attributes: !0, attributeOldValue: !0 }), this.refresh());
  }
  stop() {
    this.started && (this.mutationObserver.takeRecords(), this.mutationObserver.disconnect(), this.started = !1);
  }
  refresh() {
    if (this.started)
      for (const e of this.knownAttributeNames)
        this.refreshAttribute(e, null);
  }
  processMutations(e) {
    if (this.started)
      for (const t of e)
        this.processMutation(t);
  }
  processMutation(e) {
    const t = e.attributeName;
    t && this.refreshAttribute(t, e.oldValue);
  }
  refreshAttribute(e, t) {
    const s = this.delegate.getStringMapKeyForAttribute(e);
    if (s != null) {
      this.stringMap.has(e) || this.stringMapKeyAdded(s, e);
      const i = this.element.getAttribute(e);
      if (this.stringMap.get(e) != i && this.stringMapValueChanged(i, s, t), i == null) {
        const n = this.stringMap.get(e);
        this.stringMap.delete(e), n && this.stringMapKeyRemoved(s, e, n);
      } else
        this.stringMap.set(e, i);
    }
  }
  stringMapKeyAdded(e, t) {
    this.delegate.stringMapKeyAdded && this.delegate.stringMapKeyAdded(e, t);
  }
  stringMapValueChanged(e, t, s) {
    this.delegate.stringMapValueChanged && this.delegate.stringMapValueChanged(e, t, s);
  }
  stringMapKeyRemoved(e, t, s) {
    this.delegate.stringMapKeyRemoved && this.delegate.stringMapKeyRemoved(e, t, s);
  }
  get knownAttributeNames() {
    return Array.from(new Set(this.currentAttributeNames.concat(this.recordedAttributeNames)));
  }
  get currentAttributeNames() {
    return Array.from(this.element.attributes).map((e) => e.name);
  }
  get recordedAttributeNames() {
    return Array.from(this.stringMap.keys());
  }
}
class De {
  constructor(e, t, s) {
    this.attributeObserver = new _t(e, t, this), this.delegate = s, this.tokensByElement = new q();
  }
  get started() {
    return this.attributeObserver.started;
  }
  start() {
    this.attributeObserver.start();
  }
  pause(e) {
    this.attributeObserver.pause(e);
  }
  stop() {
    this.attributeObserver.stop();
  }
  refresh() {
    this.attributeObserver.refresh();
  }
  get element() {
    return this.attributeObserver.element;
  }
  get attributeName() {
    return this.attributeObserver.attributeName;
  }
  elementMatchedAttribute(e) {
    this.tokensMatched(this.readTokensForElement(e));
  }
  elementAttributeValueChanged(e) {
    const [t, s] = this.refreshTokensForElement(e);
    this.tokensUnmatched(t), this.tokensMatched(s);
  }
  elementUnmatchedAttribute(e) {
    this.tokensUnmatched(this.tokensByElement.getValuesForKey(e));
  }
  tokensMatched(e) {
    e.forEach((t) => this.tokenMatched(t));
  }
  tokensUnmatched(e) {
    e.forEach((t) => this.tokenUnmatched(t));
  }
  tokenMatched(e) {
    this.delegate.tokenMatched(e), this.tokensByElement.add(e.element, e);
  }
  tokenUnmatched(e) {
    this.delegate.tokenUnmatched(e), this.tokensByElement.delete(e.element, e);
  }
  refreshTokensForElement(e) {
    const t = this.tokensByElement.getValuesForKey(e), s = this.readTokensForElement(e), i = Gt(t, s).findIndex(([n, o]) => !Zt(n, o));
    return i == -1 ? [[], []] : [t.slice(i), s.slice(i)];
  }
  readTokensForElement(e) {
    const t = this.attributeName, s = e.getAttribute(t) || "";
    return Qt(s, e, t);
  }
}
function Qt(r, e, t) {
  return r.trim().split(/\s+/).filter((s) => s.length).map((s, i) => ({ element: e, attributeName: t, content: s, index: i }));
}
function Gt(r, e) {
  const t = Math.max(r.length, e.length);
  return Array.from({ length: t }, (s, i) => [r[i], e[i]]);
}
function Zt(r, e) {
  return r && e && r.index == e.index && r.content == e.content;
}
class Ne {
  constructor(e, t, s) {
    this.tokenListObserver = new De(e, t, this), this.delegate = s, this.parseResultsByToken = /* @__PURE__ */ new WeakMap(), this.valuesByTokenByElement = /* @__PURE__ */ new WeakMap();
  }
  get started() {
    return this.tokenListObserver.started;
  }
  start() {
    this.tokenListObserver.start();
  }
  stop() {
    this.tokenListObserver.stop();
  }
  refresh() {
    this.tokenListObserver.refresh();
  }
  get element() {
    return this.tokenListObserver.element;
  }
  get attributeName() {
    return this.tokenListObserver.attributeName;
  }
  tokenMatched(e) {
    const { element: t } = e, { value: s } = this.fetchParseResultForToken(e);
    s && (this.fetchValuesByTokenForElement(t).set(e, s), this.delegate.elementMatchedValue(t, s));
  }
  tokenUnmatched(e) {
    const { element: t } = e, { value: s } = this.fetchParseResultForToken(e);
    s && (this.fetchValuesByTokenForElement(t).delete(e), this.delegate.elementUnmatchedValue(t, s));
  }
  fetchParseResultForToken(e) {
    let t = this.parseResultsByToken.get(e);
    return t || (t = this.parseToken(e), this.parseResultsByToken.set(e, t)), t;
  }
  fetchValuesByTokenForElement(e) {
    let t = this.valuesByTokenByElement.get(e);
    return t || (t = /* @__PURE__ */ new Map(), this.valuesByTokenByElement.set(e, t)), t;
  }
  parseToken(e) {
    try {
      return { value: this.delegate.parseValueForToken(e) };
    } catch (t) {
      return { error: t };
    }
  }
}
class Yt {
  constructor(e, t) {
    this.context = e, this.delegate = t, this.bindingsByAction = /* @__PURE__ */ new Map();
  }
  start() {
    this.valueListObserver || (this.valueListObserver = new Ne(this.element, this.actionAttribute, this), this.valueListObserver.start());
  }
  stop() {
    this.valueListObserver && (this.valueListObserver.stop(), delete this.valueListObserver, this.disconnectAllActions());
  }
  get element() {
    return this.context.element;
  }
  get identifier() {
    return this.context.identifier;
  }
  get actionAttribute() {
    return this.schema.actionAttribute;
  }
  get schema() {
    return this.context.schema;
  }
  get bindings() {
    return Array.from(this.bindingsByAction.values());
  }
  connectAction(e) {
    const t = new Ut(this.context, e);
    this.bindingsByAction.set(e, t), this.delegate.bindingConnected(t);
  }
  disconnectAction(e) {
    const t = this.bindingsByAction.get(e);
    t && (this.bindingsByAction.delete(e), this.delegate.bindingDisconnected(t));
  }
  disconnectAllActions() {
    this.bindings.forEach((e) => this.delegate.bindingDisconnected(e, !0)), this.bindingsByAction.clear();
  }
  parseValueForToken(e) {
    const t = jt.forToken(e, this.schema);
    if (t.identifier == this.identifier)
      return t;
  }
  elementMatchedValue(e, t) {
    this.connectAction(t);
  }
  elementUnmatchedValue(e, t) {
    this.disconnectAction(t);
  }
}
class es {
  constructor(e, t) {
    this.context = e, this.receiver = t, this.stringMapObserver = new Xt(this.element, this), this.valueDescriptorMap = this.controller.valueDescriptorMap;
  }
  start() {
    this.stringMapObserver.start(), this.invokeChangedCallbacksForDefaultValues();
  }
  stop() {
    this.stringMapObserver.stop();
  }
  get element() {
    return this.context.element;
  }
  get controller() {
    return this.context.controller;
  }
  getStringMapKeyForAttribute(e) {
    if (e in this.valueDescriptorMap)
      return this.valueDescriptorMap[e].name;
  }
  stringMapKeyAdded(e, t) {
    const s = this.valueDescriptorMap[t];
    this.hasValue(e) || this.invokeChangedCallback(e, s.writer(this.receiver[e]), s.writer(s.defaultValue));
  }
  stringMapValueChanged(e, t, s) {
    const i = this.valueDescriptorNameMap[t];
    e !== null && (s === null && (s = i.writer(i.defaultValue)), this.invokeChangedCallback(t, e, s));
  }
  stringMapKeyRemoved(e, t, s) {
    const i = this.valueDescriptorNameMap[e];
    this.hasValue(e) ? this.invokeChangedCallback(e, i.writer(this.receiver[e]), s) : this.invokeChangedCallback(e, i.writer(i.defaultValue), s);
  }
  invokeChangedCallbacksForDefaultValues() {
    for (const { key: e, name: t, defaultValue: s, writer: i } of this.valueDescriptors)
      s != null && !this.controller.data.has(e) && this.invokeChangedCallback(t, i(s), void 0);
  }
  invokeChangedCallback(e, t, s) {
    const i = `${e}Changed`, n = this.receiver[i];
    if (typeof n == "function") {
      const o = this.valueDescriptorNameMap[e];
      try {
        const c = o.reader(t);
        let a = s;
        s && (a = o.reader(s)), n.call(this.receiver, c, a);
      } catch (c) {
        throw c instanceof TypeError && (c.message = `Stimulus Value "${this.context.identifier}.${o.name}" - ${c.message}`), c;
      }
    }
  }
  get valueDescriptors() {
    const { valueDescriptorMap: e } = this;
    return Object.keys(e).map((t) => e[t]);
  }
  get valueDescriptorNameMap() {
    const e = {};
    return Object.keys(this.valueDescriptorMap).forEach((t) => {
      const s = this.valueDescriptorMap[t];
      e[s.name] = s;
    }), e;
  }
  hasValue(e) {
    const t = this.valueDescriptorNameMap[e], s = `has${z(t.name)}`;
    return this.receiver[s];
  }
}
class ts {
  constructor(e, t) {
    this.context = e, this.delegate = t, this.targetsByName = new q();
  }
  start() {
    this.tokenListObserver || (this.tokenListObserver = new De(this.element, this.attributeName, this), this.tokenListObserver.start());
  }
  stop() {
    this.tokenListObserver && (this.disconnectAllTargets(), this.tokenListObserver.stop(), delete this.tokenListObserver);
  }
  tokenMatched({ element: e, content: t }) {
    this.scope.containsElement(e) && this.connectTarget(e, t);
  }
  tokenUnmatched({ element: e, content: t }) {
    this.disconnectTarget(e, t);
  }
  connectTarget(e, t) {
    var s;
    this.targetsByName.has(t, e) || (this.targetsByName.add(t, e), (s = this.tokenListObserver) === null || s === void 0 || s.pause(() => this.delegate.targetConnected(e, t)));
  }
  disconnectTarget(e, t) {
    var s;
    this.targetsByName.has(t, e) && (this.targetsByName.delete(t, e), (s = this.tokenListObserver) === null || s === void 0 || s.pause(() => this.delegate.targetDisconnected(e, t)));
  }
  disconnectAllTargets() {
    for (const e of this.targetsByName.keys)
      for (const t of this.targetsByName.getValuesForKey(e))
        this.disconnectTarget(t, e);
  }
  get attributeName() {
    return `data-${this.context.identifier}-target`;
  }
  get element() {
    return this.context.element;
  }
  get scope() {
    return this.context.scope;
  }
}
function J(r, e) {
  const t = qe(r);
  return Array.from(t.reduce((s, i) => (rs(i, e).forEach((n) => s.add(n)), s), /* @__PURE__ */ new Set()));
}
function ss(r, e) {
  return qe(r).reduce((s, i) => (s.push(...is(i, e)), s), []);
}
function qe(r) {
  const e = [];
  for (; r; )
    e.push(r), r = Object.getPrototypeOf(r);
  return e.reverse();
}
function rs(r, e) {
  const t = r[e];
  return Array.isArray(t) ? t : [];
}
function is(r, e) {
  const t = r[e];
  return t ? Object.keys(t).map((s) => [s, t[s]]) : [];
}
class ns {
  constructor(e, t) {
    this.context = e, this.delegate = t, this.outletsByName = new q(), this.outletElementsByName = new q(), this.selectorObserverMap = /* @__PURE__ */ new Map();
  }
  start() {
    this.selectorObserverMap.size === 0 && (this.outletDefinitions.forEach((e) => {
      const t = this.selector(e), s = { outletName: e };
      t && this.selectorObserverMap.set(e, new Jt(document.body, t, this, s));
    }), this.selectorObserverMap.forEach((e) => e.start())), this.dependentContexts.forEach((e) => e.refresh());
  }
  stop() {
    this.selectorObserverMap.size > 0 && (this.disconnectAllOutlets(), this.selectorObserverMap.forEach((e) => e.stop()), this.selectorObserverMap.clear());
  }
  refresh() {
    this.selectorObserverMap.forEach((e) => e.refresh());
  }
  selectorMatched(e, t, { outletName: s }) {
    const i = this.getOutlet(e, s);
    i && this.connectOutlet(i, e, s);
  }
  selectorUnmatched(e, t, { outletName: s }) {
    const i = this.getOutletFromMap(e, s);
    i && this.disconnectOutlet(i, e, s);
  }
  selectorMatchElement(e, { outletName: t }) {
    return this.hasOutlet(e, t) && e.matches(`[${this.context.application.schema.controllerAttribute}~=${t}]`);
  }
  connectOutlet(e, t, s) {
    var i;
    this.outletElementsByName.has(s, t) || (this.outletsByName.add(s, e), this.outletElementsByName.add(s, t), (i = this.selectorObserverMap.get(s)) === null || i === void 0 || i.pause(() => this.delegate.outletConnected(e, t, s)));
  }
  disconnectOutlet(e, t, s) {
    var i;
    this.outletElementsByName.has(s, t) && (this.outletsByName.delete(s, e), this.outletElementsByName.delete(s, t), (i = this.selectorObserverMap.get(s)) === null || i === void 0 || i.pause(() => this.delegate.outletDisconnected(e, t, s)));
  }
  disconnectAllOutlets() {
    for (const e of this.outletElementsByName.keys)
      for (const t of this.outletElementsByName.getValuesForKey(e))
        for (const s of this.outletsByName.getValuesForKey(e))
          this.disconnectOutlet(s, t, e);
  }
  selector(e) {
    return this.scope.outlets.getSelectorForOutletName(e);
  }
  get outletDependencies() {
    const e = new q();
    return this.router.modules.forEach((t) => {
      const s = t.definition.controllerConstructor;
      J(s, "outlets").forEach((n) => e.add(n, t.identifier));
    }), e;
  }
  get outletDefinitions() {
    return this.outletDependencies.getKeysForValue(this.identifier);
  }
  get dependentControllerIdentifiers() {
    return this.outletDependencies.getValuesForKey(this.identifier);
  }
  get dependentContexts() {
    const e = this.dependentControllerIdentifiers;
    return this.router.contexts.filter((t) => e.includes(t.identifier));
  }
  hasOutlet(e, t) {
    return !!this.getOutlet(e, t) || !!this.getOutletFromMap(e, t);
  }
  getOutlet(e, t) {
    return this.application.getControllerForElementAndIdentifier(e, t);
  }
  getOutletFromMap(e, t) {
    return this.outletsByName.getValuesForKey(t).find((s) => s.element === e);
  }
  get scope() {
    return this.context.scope;
  }
  get identifier() {
    return this.context.identifier;
  }
  get application() {
    return this.context.application;
  }
  get router() {
    return this.application.router;
  }
}
class os {
  constructor(e, t) {
    this.logDebugActivity = (s, i = {}) => {
      const { identifier: n, controller: o, element: c } = this;
      i = Object.assign({ identifier: n, controller: o, element: c }, i), this.application.logDebugActivity(this.identifier, s, i);
    }, this.module = e, this.scope = t, this.controller = new e.controllerConstructor(this), this.bindingObserver = new Yt(this, this.dispatcher), this.valueObserver = new es(this, this.controller), this.targetObserver = new ts(this, this), this.outletObserver = new ns(this, this);
    try {
      this.controller.initialize(), this.logDebugActivity("initialize");
    } catch (s) {
      this.handleError(s, "initializing controller");
    }
  }
  connect() {
    this.bindingObserver.start(), this.valueObserver.start(), this.targetObserver.start(), this.outletObserver.start();
    try {
      this.controller.connect(), this.logDebugActivity("connect");
    } catch (e) {
      this.handleError(e, "connecting controller");
    }
  }
  refresh() {
    this.outletObserver.refresh();
  }
  disconnect() {
    try {
      this.controller.disconnect(), this.logDebugActivity("disconnect");
    } catch (e) {
      this.handleError(e, "disconnecting controller");
    }
    this.outletObserver.stop(), this.targetObserver.stop(), this.valueObserver.stop(), this.bindingObserver.stop();
  }
  get application() {
    return this.module.application;
  }
  get identifier() {
    return this.module.identifier;
  }
  get schema() {
    return this.application.schema;
  }
  get dispatcher() {
    return this.application.dispatcher;
  }
  get element() {
    return this.scope.element;
  }
  get parentElement() {
    return this.element.parentElement;
  }
  handleError(e, t, s = {}) {
    const { identifier: i, controller: n, element: o } = this;
    s = Object.assign({ identifier: i, controller: n, element: o }, s), this.application.handleError(e, `Error ${t}`, s);
  }
  targetConnected(e, t) {
    this.invokeControllerMethod(`${t}TargetConnected`, e);
  }
  targetDisconnected(e, t) {
    this.invokeControllerMethod(`${t}TargetDisconnected`, e);
  }
  outletConnected(e, t, s) {
    this.invokeControllerMethod(`${re(s)}OutletConnected`, e, t);
  }
  outletDisconnected(e, t, s) {
    this.invokeControllerMethod(`${re(s)}OutletDisconnected`, e, t);
  }
  invokeControllerMethod(e, ...t) {
    const s = this.controller;
    typeof s[e] == "function" && s[e](...t);
  }
}
function as(r) {
  return cs(r, ls(r));
}
function cs(r, e) {
  const t = ms(r), s = hs(r.prototype, e);
  return Object.defineProperties(t.prototype, s), t;
}
function ls(r) {
  return J(r, "blessings").reduce((t, s) => {
    const i = s(r);
    for (const n in i) {
      const o = t[n] || {};
      t[n] = Object.assign(o, i[n]);
    }
    return t;
  }, {});
}
function hs(r, e) {
  return ds(e).reduce((t, s) => {
    const i = us(r, e, s);
    return i && Object.assign(t, { [s]: i }), t;
  }, {});
}
function us(r, e, t) {
  const s = Object.getOwnPropertyDescriptor(r, t);
  if (!(s && "value" in s)) {
    const n = Object.getOwnPropertyDescriptor(e, t).value;
    return s && (n.get = s.get || n.get, n.set = s.set || n.set), n;
  }
}
const ds = typeof Object.getOwnPropertySymbols == "function" ? (r) => [...Object.getOwnPropertyNames(r), ...Object.getOwnPropertySymbols(r)] : Object.getOwnPropertyNames, ms = (() => {
  function r(t) {
    function s() {
      return Reflect.construct(t, arguments, new.target);
    }
    return s.prototype = Object.create(t.prototype, {
      constructor: { value: s }
    }), Reflect.setPrototypeOf(s, t), s;
  }
  function e() {
    const s = r(function() {
      this.a.call(this);
    });
    return s.prototype.a = function() {
    }, new s();
  }
  try {
    return e(), r;
  } catch {
    return (s) => class extends s {
    };
  }
})();
function ps(r) {
  return {
    identifier: r.identifier,
    controllerConstructor: as(r.controllerConstructor)
  };
}
class fs {
  constructor(e, t) {
    this.application = e, this.definition = ps(t), this.contextsByScope = /* @__PURE__ */ new WeakMap(), this.connectedContexts = /* @__PURE__ */ new Set();
  }
  get identifier() {
    return this.definition.identifier;
  }
  get controllerConstructor() {
    return this.definition.controllerConstructor;
  }
  get contexts() {
    return Array.from(this.connectedContexts);
  }
  connectContextForScope(e) {
    const t = this.fetchContextForScope(e);
    this.connectedContexts.add(t), t.connect();
  }
  disconnectContextForScope(e) {
    const t = this.contextsByScope.get(e);
    t && (this.connectedContexts.delete(t), t.disconnect());
  }
  fetchContextForScope(e) {
    let t = this.contextsByScope.get(e);
    return t || (t = new os(this, e), this.contextsByScope.set(e, t)), t;
  }
}
class gs {
  constructor(e) {
    this.scope = e;
  }
  has(e) {
    return this.data.has(this.getDataKey(e));
  }
  get(e) {
    return this.getAll(e)[0];
  }
  getAll(e) {
    const t = this.data.get(this.getDataKey(e)) || "";
    return Ht(t);
  }
  getAttributeName(e) {
    return this.data.getAttributeNameForKey(this.getDataKey(e));
  }
  getDataKey(e) {
    return `${e}-class`;
  }
  get data() {
    return this.scope.data;
  }
}
class bs {
  constructor(e) {
    this.scope = e;
  }
  get element() {
    return this.scope.element;
  }
  get identifier() {
    return this.scope.identifier;
  }
  get(e) {
    const t = this.getAttributeNameForKey(e);
    return this.element.getAttribute(t);
  }
  set(e, t) {
    const s = this.getAttributeNameForKey(e);
    return this.element.setAttribute(s, t), this.get(e);
  }
  has(e) {
    const t = this.getAttributeNameForKey(e);
    return this.element.hasAttribute(t);
  }
  delete(e) {
    if (this.has(e)) {
      const t = this.getAttributeNameForKey(e);
      return this.element.removeAttribute(t), !0;
    } else
      return !1;
  }
  getAttributeNameForKey(e) {
    return `data-${this.identifier}-${xe(e)}`;
  }
}
class vs {
  constructor(e) {
    this.warnedKeysByObject = /* @__PURE__ */ new WeakMap(), this.logger = e;
  }
  warn(e, t, s) {
    let i = this.warnedKeysByObject.get(e);
    i || (i = /* @__PURE__ */ new Set(), this.warnedKeysByObject.set(e, i)), i.has(t) || (i.add(t), this.logger.warn(s, e));
  }
}
function ie(r, e) {
  return `[${r}~="${e}"]`;
}
class ys {
  constructor(e) {
    this.scope = e;
  }
  get element() {
    return this.scope.element;
  }
  get identifier() {
    return this.scope.identifier;
  }
  get schema() {
    return this.scope.schema;
  }
  has(e) {
    return this.find(e) != null;
  }
  find(...e) {
    return e.reduce((t, s) => t || this.findTarget(s) || this.findLegacyTarget(s), void 0);
  }
  findAll(...e) {
    return e.reduce((t, s) => [
      ...t,
      ...this.findAllTargets(s),
      ...this.findAllLegacyTargets(s)
    ], []);
  }
  findTarget(e) {
    const t = this.getSelectorForTargetName(e);
    return this.scope.findElement(t);
  }
  findAllTargets(e) {
    const t = this.getSelectorForTargetName(e);
    return this.scope.findAllElements(t);
  }
  getSelectorForTargetName(e) {
    const t = this.schema.targetAttributeForScope(this.identifier);
    return ie(t, e);
  }
  findLegacyTarget(e) {
    const t = this.getLegacySelectorForTargetName(e);
    return this.deprecate(this.scope.findElement(t), e);
  }
  findAllLegacyTargets(e) {
    const t = this.getLegacySelectorForTargetName(e);
    return this.scope.findAllElements(t).map((s) => this.deprecate(s, e));
  }
  getLegacySelectorForTargetName(e) {
    const t = `${this.identifier}.${e}`;
    return ie(this.schema.targetAttribute, t);
  }
  deprecate(e, t) {
    if (e) {
      const { identifier: s } = this, i = this.schema.targetAttribute, n = this.schema.targetAttributeForScope(s);
      this.guide.warn(e, `target:${t}`, `Please replace ${i}="${s}.${t}" with ${n}="${t}". The ${i} attribute is deprecated and will be removed in a future version of Stimulus.`);
    }
    return e;
  }
  get guide() {
    return this.scope.guide;
  }
}
class ws {
  constructor(e, t) {
    this.scope = e, this.controllerElement = t;
  }
  get element() {
    return this.scope.element;
  }
  get identifier() {
    return this.scope.identifier;
  }
  get schema() {
    return this.scope.schema;
  }
  has(e) {
    return this.find(e) != null;
  }
  find(...e) {
    return e.reduce((t, s) => t || this.findOutlet(s), void 0);
  }
  findAll(...e) {
    return e.reduce((t, s) => [...t, ...this.findAllOutlets(s)], []);
  }
  getSelectorForOutletName(e) {
    const t = this.schema.outletAttributeForScope(this.identifier, e);
    return this.controllerElement.getAttribute(t);
  }
  findOutlet(e) {
    const t = this.getSelectorForOutletName(e);
    if (t)
      return this.findElement(t, e);
  }
  findAllOutlets(e) {
    const t = this.getSelectorForOutletName(e);
    return t ? this.findAllElements(t, e) : [];
  }
  findElement(e, t) {
    return this.scope.queryElements(e).filter((i) => this.matchesElement(i, e, t))[0];
  }
  findAllElements(e, t) {
    return this.scope.queryElements(e).filter((i) => this.matchesElement(i, e, t));
  }
  matchesElement(e, t, s) {
    const i = e.getAttribute(this.scope.schema.controllerAttribute) || "";
    return e.matches(t) && i.split(" ").includes(s);
  }
}
class de {
  constructor(e, t, s, i) {
    this.targets = new ys(this), this.classes = new gs(this), this.data = new bs(this), this.containsElement = (n) => n.closest(this.controllerSelector) === this.element, this.schema = e, this.element = t, this.identifier = s, this.guide = new vs(i), this.outlets = new ws(this.documentScope, t);
  }
  findElement(e) {
    return this.element.matches(e) ? this.element : this.queryElements(e).find(this.containsElement);
  }
  findAllElements(e) {
    return [
      ...this.element.matches(e) ? [this.element] : [],
      ...this.queryElements(e).filter(this.containsElement)
    ];
  }
  queryElements(e) {
    return Array.from(this.element.querySelectorAll(e));
  }
  get controllerSelector() {
    return ie(this.schema.controllerAttribute, this.identifier);
  }
  get isDocumentScope() {
    return this.element === document.documentElement;
  }
  get documentScope() {
    return this.isDocumentScope ? this : new de(this.schema, document.documentElement, this.identifier, this.guide.logger);
  }
}
class Es {
  constructor(e, t, s) {
    this.element = e, this.schema = t, this.delegate = s, this.valueListObserver = new Ne(this.element, this.controllerAttribute, this), this.scopesByIdentifierByElement = /* @__PURE__ */ new WeakMap(), this.scopeReferenceCounts = /* @__PURE__ */ new WeakMap();
  }
  start() {
    this.valueListObserver.start();
  }
  stop() {
    this.valueListObserver.stop();
  }
  get controllerAttribute() {
    return this.schema.controllerAttribute;
  }
  parseValueForToken(e) {
    const { element: t, content: s } = e, i = this.fetchScopesByIdentifierForElement(t);
    let n = i.get(s);
    return n || (n = this.delegate.createScopeForElementAndIdentifier(t, s), i.set(s, n)), n;
  }
  elementMatchedValue(e, t) {
    const s = (this.scopeReferenceCounts.get(t) || 0) + 1;
    this.scopeReferenceCounts.set(t, s), s == 1 && this.delegate.scopeConnected(t);
  }
  elementUnmatchedValue(e, t) {
    const s = this.scopeReferenceCounts.get(t);
    s && (this.scopeReferenceCounts.set(t, s - 1), s == 1 && this.delegate.scopeDisconnected(t));
  }
  fetchScopesByIdentifierForElement(e) {
    let t = this.scopesByIdentifierByElement.get(e);
    return t || (t = /* @__PURE__ */ new Map(), this.scopesByIdentifierByElement.set(e, t)), t;
  }
}
class Ss {
  constructor(e) {
    this.application = e, this.scopeObserver = new Es(this.element, this.schema, this), this.scopesByIdentifier = new q(), this.modulesByIdentifier = /* @__PURE__ */ new Map();
  }
  get element() {
    return this.application.element;
  }
  get schema() {
    return this.application.schema;
  }
  get logger() {
    return this.application.logger;
  }
  get controllerAttribute() {
    return this.schema.controllerAttribute;
  }
  get modules() {
    return Array.from(this.modulesByIdentifier.values());
  }
  get contexts() {
    return this.modules.reduce((e, t) => e.concat(t.contexts), []);
  }
  start() {
    this.scopeObserver.start();
  }
  stop() {
    this.scopeObserver.stop();
  }
  loadDefinition(e) {
    this.unloadIdentifier(e.identifier);
    const t = new fs(this.application, e);
    this.connectModule(t);
    const s = e.controllerConstructor.afterLoad;
    s && s(e.identifier, this.application);
  }
  unloadIdentifier(e) {
    const t = this.modulesByIdentifier.get(e);
    t && this.disconnectModule(t);
  }
  getContextForElementAndIdentifier(e, t) {
    const s = this.modulesByIdentifier.get(t);
    if (s)
      return s.contexts.find((i) => i.element == e);
  }
  handleError(e, t, s) {
    this.application.handleError(e, t, s);
  }
  createScopeForElementAndIdentifier(e, t) {
    return new de(this.schema, e, t, this.logger);
  }
  scopeConnected(e) {
    this.scopesByIdentifier.add(e.identifier, e);
    const t = this.modulesByIdentifier.get(e.identifier);
    t && t.connectContextForScope(e);
  }
  scopeDisconnected(e) {
    this.scopesByIdentifier.delete(e.identifier, e);
    const t = this.modulesByIdentifier.get(e.identifier);
    t && t.disconnectContextForScope(e);
  }
  connectModule(e) {
    this.modulesByIdentifier.set(e.identifier, e), this.scopesByIdentifier.getValuesForKey(e.identifier).forEach((s) => e.connectContextForScope(s));
  }
  disconnectModule(e) {
    this.modulesByIdentifier.delete(e.identifier), this.scopesByIdentifier.getValuesForKey(e.identifier).forEach((s) => e.disconnectContextForScope(s));
  }
}
const Ts = {
  controllerAttribute: "data-controller",
  actionAttribute: "data-action",
  targetAttribute: "data-target",
  targetAttributeForScope: (r) => `data-${r}-target`,
  outletAttributeForScope: (r, e) => `data-${r}-${e}-outlet`,
  keyMappings: Object.assign(Object.assign({ enter: "Enter", tab: "Tab", esc: "Escape", space: " ", up: "ArrowUp", down: "ArrowDown", left: "ArrowLeft", right: "ArrowRight", home: "Home", end: "End" }, Ee("abcdefghijklmnopqrstuvwxyz".split("").map((r) => [r, r]))), Ee("0123456789".split("").map((r) => [r, r])))
};
function Ee(r) {
  return r.reduce((e, [t, s]) => Object.assign(Object.assign({}, e), { [t]: s }), {});
}
class As {
  constructor(e = document.documentElement, t = Ts) {
    this.logger = console, this.debug = !1, this.logDebugActivity = (s, i, n = {}) => {
      this.debug && this.logFormattedMessage(s, i, n);
    }, this.element = e, this.schema = t, this.dispatcher = new Pt(this), this.router = new Ss(this), this.actionDescriptorFilters = Object.assign({}, xt);
  }
  static start(e, t) {
    const s = new this(e, t);
    return s.start(), s;
  }
  async start() {
    await Cs(), this.logDebugActivity("application", "starting"), this.dispatcher.start(), this.router.start(), this.logDebugActivity("application", "start");
  }
  stop() {
    this.logDebugActivity("application", "stopping"), this.dispatcher.stop(), this.router.stop(), this.logDebugActivity("application", "stop");
  }
  register(e, t) {
    this.load({ identifier: e, controllerConstructor: t });
  }
  registerActionOption(e, t) {
    this.actionDescriptorFilters[e] = t;
  }
  load(e, ...t) {
    (Array.isArray(e) ? e : [e, ...t]).forEach((i) => {
      i.controllerConstructor.shouldLoad && this.router.loadDefinition(i);
    });
  }
  unload(e, ...t) {
    (Array.isArray(e) ? e : [e, ...t]).forEach((i) => this.router.unloadIdentifier(i));
  }
  get controllers() {
    return this.router.contexts.map((e) => e.controller);
  }
  getControllerForElementAndIdentifier(e, t) {
    const s = this.router.getContextForElementAndIdentifier(e, t);
    return s ? s.controller : null;
  }
  handleError(e, t, s) {
    var i;
    this.logger.error(`%s

%o

%o`, t, e, s), (i = window.onerror) === null || i === void 0 || i.call(window, t, "", 0, 0, e);
  }
  logFormattedMessage(e, t, s = {}) {
    s = Object.assign({ application: this }, s), this.logger.groupCollapsed(`${e} #${t}`), this.logger.log("details:", Object.assign({}, s)), this.logger.groupEnd();
  }
}
function Cs() {
  return new Promise((r) => {
    document.readyState == "loading" ? document.addEventListener("DOMContentLoaded", () => r()) : r();
  });
}
function ks(r) {
  return J(r, "classes").reduce((t, s) => Object.assign(t, Ls(s)), {});
}
function Ls(r) {
  return {
    [`${r}Class`]: {
      get() {
        const { classes: e } = this;
        if (e.has(r))
          return e.get(r);
        {
          const t = e.getAttributeName(r);
          throw new Error(`Missing attribute "${t}"`);
        }
      }
    },
    [`${r}Classes`]: {
      get() {
        return this.classes.getAll(r);
      }
    },
    [`has${z(r)}Class`]: {
      get() {
        return this.classes.has(r);
      }
    }
  };
}
function Ms(r) {
  return J(r, "outlets").reduce((t, s) => Object.assign(t, Rs(s)), {});
}
function Rs(r) {
  const e = re(r);
  return {
    [`${e}Outlet`]: {
      get() {
        const t = this.outlets.find(r);
        if (t) {
          const s = this.application.getControllerForElementAndIdentifier(t, r);
          if (s)
            return s;
          throw new Error(`Missing "data-controller=${r}" attribute on outlet element for "${this.identifier}" controller`);
        }
        throw new Error(`Missing outlet element "${r}" for "${this.identifier}" controller`);
      }
    },
    [`${e}Outlets`]: {
      get() {
        const t = this.outlets.findAll(r);
        return t.length > 0 ? t.map((s) => {
          const i = this.application.getControllerForElementAndIdentifier(s, r);
          if (i)
            return i;
          console.warn(`The provided outlet element is missing the outlet controller "${r}" for "${this.identifier}"`, s);
        }).filter((s) => s) : [];
      }
    },
    [`${e}OutletElement`]: {
      get() {
        const t = this.outlets.find(r);
        if (t)
          return t;
        throw new Error(`Missing outlet element "${r}" for "${this.identifier}" controller`);
      }
    },
    [`${e}OutletElements`]: {
      get() {
        return this.outlets.findAll(r);
      }
    },
    [`has${z(e)}Outlet`]: {
      get() {
        return this.outlets.has(r);
      }
    }
  };
}
function Os(r) {
  return J(r, "targets").reduce((t, s) => Object.assign(t, Fs(s)), {});
}
function Fs(r) {
  return {
    [`${r}Target`]: {
      get() {
        const e = this.targets.find(r);
        if (e)
          return e;
        throw new Error(`Missing target element "${r}" for "${this.identifier}" controller`);
      }
    },
    [`${r}Targets`]: {
      get() {
        return this.targets.findAll(r);
      }
    },
    [`has${z(r)}Target`]: {
      get() {
        return this.targets.has(r);
      }
    }
  };
}
function Ps(r) {
  const e = ss(r, "values"), t = {
    valueDescriptorMap: {
      get() {
        return e.reduce((s, i) => {
          const n = He(i, this.identifier), o = this.data.getAttributeNameForKey(n.key);
          return Object.assign(s, { [o]: n });
        }, {});
      }
    }
  };
  return e.reduce((s, i) => Object.assign(s, xs(i)), t);
}
function xs(r, e) {
  const t = He(r, e), { key: s, name: i, reader: n, writer: o } = t;
  return {
    [i]: {
      get() {
        const c = this.data.get(s);
        return c !== null ? n(c) : t.defaultValue;
      },
      set(c) {
        c === void 0 ? this.data.delete(s) : this.data.set(s, o(c));
      }
    },
    [`has${z(i)}`]: {
      get() {
        return this.data.has(s) || t.hasCustomDefaultValue;
      }
    }
  };
}
function He([r, e], t) {
  return Ns({
    controller: t,
    token: r,
    typeDefinition: e
  });
}
function me(r) {
  switch (r) {
    case Array:
      return "array";
    case Boolean:
      return "boolean";
    case Number:
      return "number";
    case Object:
      return "object";
    case String:
      return "string";
  }
}
function _(r) {
  switch (typeof r) {
    case "boolean":
      return "boolean";
    case "number":
      return "number";
    case "string":
      return "string";
  }
  if (Array.isArray(r))
    return "array";
  if (Object.prototype.toString.call(r) === "[object Object]")
    return "object";
}
function Is(r) {
  const e = me(r.typeObject.type);
  if (!e)
    return;
  const t = _(r.typeObject.default);
  if (e !== t) {
    const s = r.controller ? `${r.controller}.${r.token}` : r.token;
    throw new Error(`The specified default value for the Stimulus Value "${s}" must match the defined type "${e}". The provided default value of "${r.typeObject.default}" is of type "${t}".`);
  }
  return e;
}
function Bs(r) {
  const e = Is({
    controller: r.controller,
    token: r.token,
    typeObject: r.typeDefinition
  }), t = _(r.typeDefinition), s = me(r.typeDefinition), i = e || t || s;
  if (i)
    return i;
  const n = r.controller ? `${r.controller}.${r.typeDefinition}` : r.token;
  throw new Error(`Unknown value type "${n}" for "${r.token}" value`);
}
function Ds(r) {
  const e = me(r);
  if (e)
    return qs[e];
  const t = r.default;
  return t !== void 0 ? t : r;
}
function Ns(r) {
  const e = `${xe(r.token)}-value`, t = Bs(r);
  return {
    type: t,
    key: e,
    name: ue(e),
    get defaultValue() {
      return Ds(r.typeDefinition);
    },
    get hasCustomDefaultValue() {
      return _(r.typeDefinition) !== void 0;
    },
    reader: Hs[t],
    writer: Se[t] || Se.default
  };
}
const qs = {
  get array() {
    return [];
  },
  boolean: !1,
  number: 0,
  get object() {
    return {};
  },
  string: ""
}, Hs = {
  array(r) {
    const e = JSON.parse(r);
    if (!Array.isArray(e))
      throw new TypeError(`expected value of type "array" but instead got value "${r}" of type "${_(e)}"`);
    return e;
  },
  boolean(r) {
    return !(r == "0" || String(r).toLowerCase() == "false");
  },
  number(r) {
    return Number(r);
  },
  object(r) {
    const e = JSON.parse(r);
    if (e === null || typeof e != "object" || Array.isArray(e))
      throw new TypeError(`expected value of type "object" but instead got value "${r}" of type "${_(e)}"`);
    return e;
  },
  string(r) {
    return r;
  }
}, Se = {
  default: js,
  array: Te,
  object: Te
};
function Te(r) {
  return JSON.stringify(r);
}
function js(r) {
  return `${r}`;
}
let T = class {
  constructor(e) {
    this.context = e;
  }
  static get shouldLoad() {
    return !0;
  }
  static afterLoad(e, t) {
  }
  get application() {
    return this.context.application;
  }
  get scope() {
    return this.context.scope;
  }
  get element() {
    return this.scope.element;
  }
  get identifier() {
    return this.scope.identifier;
  }
  get targets() {
    return this.scope.targets;
  }
  get outlets() {
    return this.scope.outlets;
  }
  get classes() {
    return this.scope.classes;
  }
  get data() {
    return this.scope.data;
  }
  initialize() {
  }
  connect() {
  }
  disconnect() {
  }
  dispatch(e, { target: t = this.element, detail: s = {}, prefix: i = this.identifier, bubbles: n = !0, cancelable: o = !0 } = {}) {
    const c = i ? `${i}:${e}` : e, a = new CustomEvent(c, { detail: s, bubbles: n, cancelable: o });
    return t.dispatchEvent(a), a;
  }
};
T.blessings = [
  ks,
  Os,
  Ps,
  Ms
];
T.targets = [];
T.outlets = [];
T.values = {};
class v extends T {
  createTarget(e, t = {}) {
    const s = t.element || "div", i = t.htmlOptions || {}, n = t.data || {}, o = t.innerHTML || "", c = document.createElement(s);
    return Object.keys(n).forEach((a) => {
      c.setAttribute(`data-${a}`, n[a]);
    }), Object.keys(i).forEach((a) => {
      c.setAttribute(a, i[a]);
    }), c.setAttribute(`data-${this.identifier}-target`, `${e}`), c.innerHTML = o, c;
  }
  /**
   * Get instance of remote controller
   * if element id not passed, will be eq to controller name
   * @param controller
   * @param id
   */
  connectRemote(e, t = null) {
    const s = t || e, i = document.getElementById(s);
    return this.application.getControllerForElementAndIdentifier(i, e);
  }
}
const je = (r) => r.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g) !== null, $s = (r, e) => {
  const t = document.createElement("a");
  t.href = r, t.setAttribute("download", e), t.click();
};
(function() {
  if (window.Reflect === void 0 || window.customElements === void 0 || window.customElements.polyfillWrapFlushCallback)
    return;
  const r = HTMLElement, e = {
    HTMLElement: function() {
      return Reflect.construct(r, [], this.constructor);
    }
  };
  window.HTMLElement = e.HTMLElement, HTMLElement.prototype = r.prototype, HTMLElement.prototype.constructor = HTMLElement, Object.setPrototypeOf(HTMLElement, r);
})();
(function(r) {
  if (typeof r.requestSubmit == "function") return;
  r.requestSubmit = function(s) {
    s ? (e(s, this), s.click()) : (s = document.createElement("input"), s.type = "submit", s.hidden = !0, this.appendChild(s), s.click(), this.removeChild(s));
  };
  function e(s, i) {
    s instanceof HTMLElement || t(TypeError, "parameter 1 is not of type 'HTMLElement'"), s.type == "submit" || t(TypeError, "The specified element is not a submit button"), s.form == i || t(DOMException, "The specified element is not owned by this form element", "NotFoundError");
  }
  function t(s, i, n) {
    throw new s("Failed to execute 'requestSubmit' on 'HTMLFormElement': " + i + ".", n);
  }
})(HTMLFormElement.prototype);
const $e = /* @__PURE__ */ new WeakMap();
function Vs(r) {
  const e = r instanceof Element ? r : r instanceof Node ? r.parentElement : null, t = e ? e.closest("input, button") : null;
  return (t == null ? void 0 : t.type) == "submit" ? t : null;
}
function Us(r) {
  const e = Vs(r.target);
  e && e.form && $e.set(e.form, e);
}
(function() {
  if ("submitter" in Event.prototype)
    return;
  let r;
  if ("SubmitEvent" in window && /Apple Computer/.test(navigator.vendor))
    r = window.SubmitEvent.prototype;
  else {
    if ("SubmitEvent" in window)
      return;
    r = window.Event.prototype;
  }
  addEventListener("click", Us, !0), Object.defineProperty(r, "submitter", {
    get() {
      if (this.type == "submit" && this.target instanceof HTMLFormElement)
        return $e.get(this.target);
    }
  });
})();
var x;
(function(r) {
  r.eager = "eager", r.lazy = "lazy";
})(x || (x = {}));
class D extends HTMLElement {
  constructor() {
    super(), this.loaded = Promise.resolve(), this.delegate = new D.delegateConstructor(this);
  }
  static get observedAttributes() {
    return ["disabled", "loading", "src"];
  }
  connectedCallback() {
    this.delegate.connect();
  }
  disconnectedCallback() {
    this.delegate.disconnect();
  }
  reload() {
    const { src: e } = this;
    this.src = null, this.src = e;
  }
  attributeChangedCallback(e) {
    e == "loading" ? this.delegate.loadingStyleChanged() : e == "src" ? this.delegate.sourceURLChanged() : this.delegate.disabledChanged();
  }
  get src() {
    return this.getAttribute("src");
  }
  set src(e) {
    e ? this.setAttribute("src", e) : this.removeAttribute("src");
  }
  get loading() {
    return _s(this.getAttribute("loading") || "");
  }
  set loading(e) {
    e ? this.setAttribute("loading", e) : this.removeAttribute("loading");
  }
  get disabled() {
    return this.hasAttribute("disabled");
  }
  set disabled(e) {
    e ? this.setAttribute("disabled", "") : this.removeAttribute("disabled");
  }
  get autoscroll() {
    return this.hasAttribute("autoscroll");
  }
  set autoscroll(e) {
    e ? this.setAttribute("autoscroll", "") : this.removeAttribute("autoscroll");
  }
  get complete() {
    return !this.delegate.isLoading;
  }
  get isActive() {
    return this.ownerDocument === document && !this.isPreview;
  }
  get isPreview() {
    var e, t;
    return (t = (e = this.ownerDocument) === null || e === void 0 ? void 0 : e.documentElement) === null || t === void 0 ? void 0 : t.hasAttribute("data-turbo-preview");
  }
}
function _s(r) {
  switch (r.toLowerCase()) {
    case "lazy":
      return x.lazy;
    default:
      return x.eager;
  }
}
function S(r) {
  return new URL(r.toString(), document.baseURI);
}
function H(r) {
  let e;
  if (r.hash)
    return r.hash.slice(1);
  if (e = r.href.match(/#(.*)$/))
    return e[1];
}
function pe(r, e) {
  const t = (e == null ? void 0 : e.getAttribute("formaction")) || r.getAttribute("action") || r.action;
  return S(t);
}
function Ws(r) {
  return (Qs(r).match(/\.[^.]*$/) || [])[0] || "";
}
function Ks(r) {
  return !!Ws(r).match(/^(?:|\.(?:htm|html|xhtml))$/);
}
function zs(r, e) {
  const t = Gs(e);
  return r.href === S(t).href || r.href.startsWith(t);
}
function W(r, e) {
  return zs(r, e) && Ks(r);
}
function ne(r) {
  const e = H(r);
  return e != null ? r.href.slice(0, -(e.length + 1)) : r.href;
}
function Z(r) {
  return ne(r);
}
function Js(r, e) {
  return S(r).href == S(e).href;
}
function Xs(r) {
  return r.pathname.split("/").slice(1);
}
function Qs(r) {
  return Xs(r).slice(-1)[0];
}
function Gs(r) {
  return Zs(r.origin + r.pathname);
}
function Zs(r) {
  return r.endsWith("/") ? r : r + "/";
}
let Ve = class {
  constructor(e) {
    this.response = e;
  }
  get succeeded() {
    return this.response.ok;
  }
  get failed() {
    return !this.succeeded;
  }
  get clientError() {
    return this.statusCode >= 400 && this.statusCode <= 499;
  }
  get serverError() {
    return this.statusCode >= 500 && this.statusCode <= 599;
  }
  get redirected() {
    return this.response.redirected;
  }
  get location() {
    return S(this.response.url);
  }
  get isHTML() {
    return this.contentType && this.contentType.match(/^(?:text\/([^\s;,]+\b)?html|application\/xhtml\+xml)\b/);
  }
  get statusCode() {
    return this.response.status;
  }
  get contentType() {
    return this.header("Content-Type");
  }
  get responseText() {
    return this.response.clone().text();
  }
  get responseHTML() {
    return this.isHTML ? this.response.clone().text() : Promise.resolve(void 0);
  }
  header(e) {
    return this.response.headers.get(e);
  }
};
function A(r, { target: e, cancelable: t, detail: s } = {}) {
  const i = new CustomEvent(r, { cancelable: t, bubbles: !0, detail: s });
  return e && e.isConnected ? e.dispatchEvent(i) : document.documentElement.dispatchEvent(i), i;
}
function Y() {
  return new Promise((r) => requestAnimationFrame(() => r()));
}
function Ys() {
  return new Promise((r) => setTimeout(() => r(), 0));
}
function er() {
  return Promise.resolve();
}
function Ue(r = "") {
  return new DOMParser().parseFromString(r, "text/html");
}
function _e(r, ...e) {
  const t = tr(r, e).replace(/^\n/, "").split(`
`), s = t[0].match(/^\s+/), i = s ? s[0].length : 0;
  return t.map((n) => n.slice(i)).join(`
`);
}
function tr(r, e) {
  return r.reduce((t, s, i) => {
    const n = e[i] == null ? "" : e[i];
    return t + s + n;
  }, "");
}
function K() {
  return Array.apply(null, { length: 36 }).map((r, e) => e == 8 || e == 13 || e == 18 || e == 23 ? "-" : e == 14 ? "4" : e == 19 ? (Math.floor(Math.random() * 4) + 8).toString(16) : Math.floor(Math.random() * 15).toString(16)).join("");
}
function ee(r, ...e) {
  for (const t of e.map((s) => s == null ? void 0 : s.getAttribute(r)))
    if (typeof t == "string")
      return t;
  return null;
}
function oe(...r) {
  for (const e of r)
    e.localName == "turbo-frame" && e.setAttribute("busy", ""), e.setAttribute("aria-busy", "true");
}
function ae(...r) {
  for (const e of r)
    e.localName == "turbo-frame" && e.removeAttribute("busy"), e.removeAttribute("aria-busy");
}
var w;
(function(r) {
  r[r.get = 0] = "get", r[r.post = 1] = "post", r[r.put = 2] = "put", r[r.patch = 3] = "patch", r[r.delete = 4] = "delete";
})(w || (w = {}));
function sr(r) {
  switch (r.toLowerCase()) {
    case "get":
      return w.get;
    case "post":
      return w.post;
    case "put":
      return w.put;
    case "patch":
      return w.patch;
    case "delete":
      return w.delete;
  }
}
let fe = class {
  constructor(e, t, s, i = new URLSearchParams(), n = null) {
    this.abortController = new AbortController(), this.resolveRequestPromise = (o) => {
    }, this.delegate = e, this.method = t, this.headers = this.defaultHeaders, this.body = i, this.url = s, this.target = n;
  }
  get location() {
    return this.url;
  }
  get params() {
    return this.url.searchParams;
  }
  get entries() {
    return this.body ? Array.from(this.body.entries()) : [];
  }
  cancel() {
    this.abortController.abort();
  }
  async perform() {
    var e, t;
    const { fetchOptions: s } = this;
    (t = (e = this.delegate).prepareHeadersForRequest) === null || t === void 0 || t.call(e, this.headers, this), await this.allowRequestToBeIntercepted(s);
    try {
      this.delegate.requestStarted(this);
      const i = await fetch(this.url.href, s);
      return await this.receive(i);
    } catch (i) {
      if (i.name !== "AbortError")
        throw this.delegate.requestErrored(this, i), i;
    } finally {
      this.delegate.requestFinished(this);
    }
  }
  async receive(e) {
    const t = new Ve(e);
    return A("turbo:before-fetch-response", { cancelable: !0, detail: { fetchResponse: t }, target: this.target }).defaultPrevented ? this.delegate.requestPreventedHandlingResponse(this, t) : t.succeeded ? this.delegate.requestSucceededWithResponse(this, t) : this.delegate.requestFailedWithResponse(this, t), t;
  }
  get fetchOptions() {
    var e;
    return {
      method: w[this.method].toUpperCase(),
      credentials: "same-origin",
      headers: this.headers,
      redirect: "follow",
      body: this.isIdempotent ? null : this.body,
      signal: this.abortSignal,
      referrer: (e = this.delegate.referrer) === null || e === void 0 ? void 0 : e.href
    };
  }
  get defaultHeaders() {
    return {
      Accept: "text/html, application/xhtml+xml"
    };
  }
  get isIdempotent() {
    return this.method == w.get;
  }
  get abortSignal() {
    return this.abortController.signal;
  }
  async allowRequestToBeIntercepted(e) {
    const t = new Promise((i) => this.resolveRequestPromise = i);
    A("turbo:before-fetch-request", {
      cancelable: !0,
      detail: {
        fetchOptions: e,
        url: this.url,
        resume: this.resolveRequestPromise
      },
      target: this.target
    }).defaultPrevented && await t;
  }
};
class rr {
  constructor(e, t) {
    this.started = !1, this.intersect = (s) => {
      const i = s.slice(-1)[0];
      i != null && i.isIntersecting && this.delegate.elementAppearedInViewport(this.element);
    }, this.delegate = e, this.element = t, this.intersectionObserver = new IntersectionObserver(this.intersect);
  }
  start() {
    this.started || (this.started = !0, this.intersectionObserver.observe(this.element));
  }
  stop() {
    this.started && (this.started = !1, this.intersectionObserver.unobserve(this.element));
  }
}
class X {
  constructor(e) {
    this.templateElement = document.createElement("template"), this.templateElement.innerHTML = e;
  }
  static wrap(e) {
    return typeof e == "string" ? new this(e) : e;
  }
  get fragment() {
    const e = document.createDocumentFragment();
    for (const t of this.foreignElements)
      e.appendChild(document.importNode(t, !0));
    return e;
  }
  get foreignElements() {
    return this.templateChildren.reduce((e, t) => t.tagName.toLowerCase() == "turbo-stream" ? [...e, t] : e, []);
  }
  get templateChildren() {
    return Array.from(this.templateElement.content.children);
  }
}
X.contentType = "text/vnd.turbo-stream.html";
var F;
(function(r) {
  r[r.initialized = 0] = "initialized", r[r.requesting = 1] = "requesting", r[r.waiting = 2] = "waiting", r[r.receiving = 3] = "receiving", r[r.stopping = 4] = "stopping", r[r.stopped = 5] = "stopped";
})(F || (F = {}));
var P;
(function(r) {
  r.urlEncoded = "application/x-www-form-urlencoded", r.multipart = "multipart/form-data", r.plain = "text/plain";
})(P || (P = {}));
function ir(r) {
  switch (r.toLowerCase()) {
    case P.multipart:
      return P.multipart;
    case P.plain:
      return P.plain;
    default:
      return P.urlEncoded;
  }
}
class Q {
  constructor(e, t, s, i = !1) {
    this.state = F.initialized, this.delegate = e, this.formElement = t, this.submitter = s, this.formData = nr(t, s), this.location = S(this.action), this.method == w.get && cr(this.location, [...this.body.entries()]), this.fetchRequest = new fe(this, this.method, this.location, this.body, this.formElement), this.mustRedirect = i;
  }
  static confirmMethod(e, t) {
    return confirm(e);
  }
  get method() {
    var e;
    const t = ((e = this.submitter) === null || e === void 0 ? void 0 : e.getAttribute("formmethod")) || this.formElement.getAttribute("method") || "";
    return sr(t.toLowerCase()) || w.get;
  }
  get action() {
    var e;
    const t = typeof this.formElement.action == "string" ? this.formElement.action : null;
    return ((e = this.submitter) === null || e === void 0 ? void 0 : e.getAttribute("formaction")) || this.formElement.getAttribute("action") || t || "";
  }
  get body() {
    return this.enctype == P.urlEncoded || this.method == w.get ? new URLSearchParams(this.stringFormData) : this.formData;
  }
  get enctype() {
    var e;
    return ir(((e = this.submitter) === null || e === void 0 ? void 0 : e.getAttribute("formenctype")) || this.formElement.enctype);
  }
  get isIdempotent() {
    return this.fetchRequest.isIdempotent;
  }
  get stringFormData() {
    return [...this.formData].reduce((e, [t, s]) => e.concat(typeof s == "string" ? [[t, s]] : []), []);
  }
  get confirmationMessage() {
    return this.formElement.getAttribute("data-turbo-confirm");
  }
  get needsConfirmation() {
    return this.confirmationMessage !== null;
  }
  async start() {
    const { initialized: e, requesting: t } = F;
    if (!(this.needsConfirmation && !Q.confirmMethod(this.confirmationMessage, this.formElement)) && this.state == e)
      return this.state = t, this.fetchRequest.perform();
  }
  stop() {
    const { stopping: e, stopped: t } = F;
    if (this.state != e && this.state != t)
      return this.state = e, this.fetchRequest.cancel(), !0;
  }
  prepareHeadersForRequest(e, t) {
    if (!t.isIdempotent) {
      const s = or(Ae("csrf-param")) || Ae("csrf-token");
      s && (e["X-CSRF-Token"] = s), e.Accept = [X.contentType, e.Accept].join(", ");
    }
  }
  requestStarted(e) {
    var t;
    this.state = F.waiting, (t = this.submitter) === null || t === void 0 || t.setAttribute("disabled", ""), A("turbo:submit-start", { target: this.formElement, detail: { formSubmission: this } }), this.delegate.formSubmissionStarted(this);
  }
  requestPreventedHandlingResponse(e, t) {
    this.result = { success: t.succeeded, fetchResponse: t };
  }
  requestSucceededWithResponse(e, t) {
    if (t.clientError || t.serverError)
      this.delegate.formSubmissionFailedWithResponse(this, t);
    else if (this.requestMustRedirect(e) && ar(t)) {
      const s = new Error("Form responses must redirect to another location");
      this.delegate.formSubmissionErrored(this, s);
    } else
      this.state = F.receiving, this.result = { success: !0, fetchResponse: t }, this.delegate.formSubmissionSucceededWithResponse(this, t);
  }
  requestFailedWithResponse(e, t) {
    this.result = { success: !1, fetchResponse: t }, this.delegate.formSubmissionFailedWithResponse(this, t);
  }
  requestErrored(e, t) {
    this.result = { success: !1, error: t }, this.delegate.formSubmissionErrored(this, t);
  }
  requestFinished(e) {
    var t;
    this.state = F.stopped, (t = this.submitter) === null || t === void 0 || t.removeAttribute("disabled"), A("turbo:submit-end", { target: this.formElement, detail: Object.assign({ formSubmission: this }, this.result) }), this.delegate.formSubmissionFinished(this);
  }
  requestMustRedirect(e) {
    return !e.isIdempotent && this.mustRedirect;
  }
}
function nr(r, e) {
  const t = new FormData(r), s = e == null ? void 0 : e.getAttribute("name"), i = e == null ? void 0 : e.getAttribute("value");
  return s && i != null && t.get(s) != i && t.append(s, i), t;
}
function or(r) {
  if (r != null) {
    const t = (document.cookie ? document.cookie.split("; ") : []).find((s) => s.startsWith(r));
    if (t) {
      const s = t.split("=").slice(1).join("=");
      return s ? decodeURIComponent(s) : void 0;
    }
  }
}
function Ae(r) {
  const e = document.querySelector(`meta[name="${r}"]`);
  return e && e.content;
}
function ar(r) {
  return r.statusCode == 200 && !r.redirected;
}
function cr(r, e) {
  const t = new URLSearchParams();
  for (const [s, i] of e)
    i instanceof File || t.append(s, i);
  return r.search = t.toString(), r;
}
class te {
  constructor(e) {
    this.element = e;
  }
  get children() {
    return [...this.element.children];
  }
  hasAnchor(e) {
    return this.getElementForAnchor(e) != null;
  }
  getElementForAnchor(e) {
    return e ? this.element.querySelector(`[id='${e}'], a[name='${e}']`) : null;
  }
  get isConnected() {
    return this.element.isConnected;
  }
  get firstAutofocusableElement() {
    return this.element.querySelector("[autofocus]");
  }
  get permanentElements() {
    return [...this.element.querySelectorAll("[id][data-turbo-permanent]")];
  }
  getPermanentElementById(e) {
    return this.element.querySelector(`#${e}[data-turbo-permanent]`);
  }
  getPermanentElementMapForSnapshot(e) {
    const t = {};
    for (const s of this.permanentElements) {
      const { id: i } = s, n = e.getPermanentElementById(i);
      n && (t[i] = [s, n]);
    }
    return t;
  }
}
class We {
  constructor(e, t) {
    this.submitBubbled = (s) => {
      const i = s.target;
      if (!s.defaultPrevented && i instanceof HTMLFormElement && i.closest("turbo-frame, html") == this.element) {
        const n = s.submitter || void 0;
        ((n == null ? void 0 : n.getAttribute("formmethod")) || i.method) != "dialog" && this.delegate.shouldInterceptFormSubmission(i, n) && (s.preventDefault(), s.stopImmediatePropagation(), this.delegate.formSubmissionIntercepted(i, n));
      }
    }, this.delegate = e, this.element = t;
  }
  start() {
    this.element.addEventListener("submit", this.submitBubbled);
  }
  stop() {
    this.element.removeEventListener("submit", this.submitBubbled);
  }
}
class Ke {
  constructor(e, t) {
    this.resolveRenderPromise = (s) => {
    }, this.resolveInterceptionPromise = (s) => {
    }, this.delegate = e, this.element = t;
  }
  scrollToAnchor(e) {
    const t = this.snapshot.getElementForAnchor(e);
    t ? (this.scrollToElement(t), this.focusElement(t)) : this.scrollToPosition({ x: 0, y: 0 });
  }
  scrollToAnchorFromLocation(e) {
    this.scrollToAnchor(H(e));
  }
  scrollToElement(e) {
    e.scrollIntoView();
  }
  focusElement(e) {
    e instanceof HTMLElement && (e.hasAttribute("tabindex") ? e.focus() : (e.setAttribute("tabindex", "-1"), e.focus(), e.removeAttribute("tabindex")));
  }
  scrollToPosition({ x: e, y: t }) {
    this.scrollRoot.scrollTo(e, t);
  }
  scrollToTop() {
    this.scrollToPosition({ x: 0, y: 0 });
  }
  get scrollRoot() {
    return window;
  }
  async render(e) {
    const { isPreview: t, shouldRender: s, newSnapshot: i } = e;
    if (s)
      try {
        this.renderPromise = new Promise((c) => this.resolveRenderPromise = c), this.renderer = e, this.prepareToRenderSnapshot(e);
        const n = new Promise((c) => this.resolveInterceptionPromise = c);
        this.delegate.allowsImmediateRender(i, this.resolveInterceptionPromise) || await n, await this.renderSnapshot(e), this.delegate.viewRenderedSnapshot(i, t), this.finishRenderingSnapshot(e);
      } finally {
        delete this.renderer, this.resolveRenderPromise(void 0), delete this.renderPromise;
      }
    else
      this.invalidate();
  }
  invalidate() {
    this.delegate.viewInvalidated();
  }
  prepareToRenderSnapshot(e) {
    this.markAsPreview(e.isPreview), e.prepareToRender();
  }
  markAsPreview(e) {
    e ? this.element.setAttribute("data-turbo-preview", "") : this.element.removeAttribute("data-turbo-preview");
  }
  async renderSnapshot(e) {
    await e.render();
  }
  finishRenderingSnapshot(e) {
    e.finishRendering();
  }
}
class lr extends Ke {
  invalidate() {
    this.element.innerHTML = "";
  }
  get snapshot() {
    return new te(this.element);
  }
}
class ze {
  constructor(e, t) {
    this.clickBubbled = (s) => {
      this.respondsToEventTarget(s.target) ? this.clickEvent = s : delete this.clickEvent;
    }, this.linkClicked = (s) => {
      this.clickEvent && this.respondsToEventTarget(s.target) && s.target instanceof Element && this.delegate.shouldInterceptLinkClick(s.target, s.detail.url) && (this.clickEvent.preventDefault(), s.preventDefault(), this.delegate.linkClickIntercepted(s.target, s.detail.url)), delete this.clickEvent;
    }, this.willVisit = () => {
      delete this.clickEvent;
    }, this.delegate = e, this.element = t;
  }
  start() {
    this.element.addEventListener("click", this.clickBubbled), document.addEventListener("turbo:click", this.linkClicked), document.addEventListener("turbo:before-visit", this.willVisit);
  }
  stop() {
    this.element.removeEventListener("click", this.clickBubbled), document.removeEventListener("turbo:click", this.linkClicked), document.removeEventListener("turbo:before-visit", this.willVisit);
  }
  respondsToEventTarget(e) {
    const t = e instanceof Element ? e : e instanceof Node ? e.parentElement : null;
    return t && t.closest("turbo-frame, html") == this.element;
  }
}
class hr {
  constructor(e) {
    this.permanentElementMap = e;
  }
  static preservingPermanentElements(e, t) {
    const s = new this(e);
    s.enter(), t(), s.leave();
  }
  enter() {
    for (const e in this.permanentElementMap) {
      const [, t] = this.permanentElementMap[e];
      this.replaceNewPermanentElementWithPlaceholder(t);
    }
  }
  leave() {
    for (const e in this.permanentElementMap) {
      const [t] = this.permanentElementMap[e];
      this.replaceCurrentPermanentElementWithClone(t), this.replacePlaceholderWithPermanentElement(t);
    }
  }
  replaceNewPermanentElementWithPlaceholder(e) {
    const t = ur(e);
    e.replaceWith(t);
  }
  replaceCurrentPermanentElementWithClone(e) {
    const t = e.cloneNode(!0);
    e.replaceWith(t);
  }
  replacePlaceholderWithPermanentElement(e) {
    const t = this.getPlaceholderById(e.id);
    t == null || t.replaceWith(e);
  }
  getPlaceholderById(e) {
    return this.placeholders.find((t) => t.content == e);
  }
  get placeholders() {
    return [...document.querySelectorAll("meta[name=turbo-permanent-placeholder][content]")];
  }
}
function ur(r) {
  const e = document.createElement("meta");
  return e.setAttribute("name", "turbo-permanent-placeholder"), e.setAttribute("content", r.id), e;
}
class ge {
  constructor(e, t, s, i = !0) {
    this.currentSnapshot = e, this.newSnapshot = t, this.isPreview = s, this.willRender = i, this.promise = new Promise((n, o) => this.resolvingFunctions = { resolve: n, reject: o });
  }
  get shouldRender() {
    return !0;
  }
  prepareToRender() {
  }
  finishRendering() {
    this.resolvingFunctions && (this.resolvingFunctions.resolve(), delete this.resolvingFunctions);
  }
  createScriptElement(e) {
    if (e.getAttribute("data-turbo-eval") == "false")
      return e;
    {
      const t = document.createElement("script");
      return this.cspNonce && (t.nonce = this.cspNonce), t.textContent = e.textContent, t.async = !1, dr(t, e), t;
    }
  }
  preservingPermanentElements(e) {
    hr.preservingPermanentElements(this.permanentElementMap, e);
  }
  focusFirstAutofocusableElement() {
    const e = this.connectedSnapshot.firstAutofocusableElement;
    mr(e) && e.focus();
  }
  get connectedSnapshot() {
    return this.newSnapshot.isConnected ? this.newSnapshot : this.currentSnapshot;
  }
  get currentElement() {
    return this.currentSnapshot.element;
  }
  get newElement() {
    return this.newSnapshot.element;
  }
  get permanentElementMap() {
    return this.currentSnapshot.getPermanentElementMapForSnapshot(this.newSnapshot);
  }
  get cspNonce() {
    var e;
    return (e = document.head.querySelector('meta[name="csp-nonce"]')) === null || e === void 0 ? void 0 : e.getAttribute("content");
  }
}
function dr(r, e) {
  for (const { name: t, value: s } of [...e.attributes])
    r.setAttribute(t, s);
}
function mr(r) {
  return r && typeof r.focus == "function";
}
class pr extends ge {
  get shouldRender() {
    return !0;
  }
  async render() {
    await Y(), this.preservingPermanentElements(() => {
      this.loadFrameElement();
    }), this.scrollFrameIntoView(), await Y(), this.focusFirstAutofocusableElement(), await Y(), this.activateScriptElements();
  }
  loadFrameElement() {
    var e;
    const t = document.createRange();
    t.selectNodeContents(this.currentElement), t.deleteContents();
    const s = this.newElement, i = (e = s.ownerDocument) === null || e === void 0 ? void 0 : e.createRange();
    i && (i.selectNodeContents(s), this.currentElement.appendChild(i.extractContents()));
  }
  scrollFrameIntoView() {
    if (this.currentElement.autoscroll || this.newElement.autoscroll) {
      const e = this.currentElement.firstElementChild, t = fr(this.currentElement.getAttribute("data-autoscroll-block"), "end");
      if (e)
        return e.scrollIntoView({ block: t }), !0;
    }
    return !1;
  }
  activateScriptElements() {
    for (const e of this.newScriptElements) {
      const t = this.createScriptElement(e);
      e.replaceWith(t);
    }
  }
  get newScriptElements() {
    return this.currentElement.querySelectorAll("script");
  }
}
function fr(r, e) {
  return r == "end" || r == "start" || r == "center" || r == "nearest" ? r : e;
}
class R {
  constructor() {
    this.hiding = !1, this.value = 0, this.visible = !1, this.trickle = () => {
      this.setValue(this.value + Math.random() / 100);
    }, this.stylesheetElement = this.createStylesheetElement(), this.progressElement = this.createProgressElement(), this.installStylesheetElement(), this.setValue(0);
  }
  static get defaultCSS() {
    return _e`
      .turbo-progress-bar {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        height: 3px;
        background: #0076ff;
        z-index: 9999;
        transition:
          width ${R.animationDuration}ms ease-out,
          opacity ${R.animationDuration / 2}ms ${R.animationDuration / 2}ms ease-in;
        transform: translate3d(0, 0, 0);
      }
    `;
  }
  show() {
    this.visible || (this.visible = !0, this.installProgressElement(), this.startTrickling());
  }
  hide() {
    this.visible && !this.hiding && (this.hiding = !0, this.fadeProgressElement(() => {
      this.uninstallProgressElement(), this.stopTrickling(), this.visible = !1, this.hiding = !1;
    }));
  }
  setValue(e) {
    this.value = e, this.refresh();
  }
  installStylesheetElement() {
    document.head.insertBefore(this.stylesheetElement, document.head.firstChild);
  }
  installProgressElement() {
    this.progressElement.style.width = "0", this.progressElement.style.opacity = "1", document.documentElement.insertBefore(this.progressElement, document.body), this.refresh();
  }
  fadeProgressElement(e) {
    this.progressElement.style.opacity = "0", setTimeout(e, R.animationDuration * 1.5);
  }
  uninstallProgressElement() {
    this.progressElement.parentNode && document.documentElement.removeChild(this.progressElement);
  }
  startTrickling() {
    this.trickleInterval || (this.trickleInterval = window.setInterval(this.trickle, R.animationDuration));
  }
  stopTrickling() {
    window.clearInterval(this.trickleInterval), delete this.trickleInterval;
  }
  refresh() {
    requestAnimationFrame(() => {
      this.progressElement.style.width = `${10 + this.value * 90}%`;
    });
  }
  createStylesheetElement() {
    const e = document.createElement("style");
    return e.type = "text/css", e.textContent = R.defaultCSS, e;
  }
  createProgressElement() {
    const e = document.createElement("div");
    return e.className = "turbo-progress-bar", e;
  }
}
R.animationDuration = 300;
class gr extends te {
  constructor() {
    super(...arguments), this.detailsByOuterHTML = this.children.filter((e) => !wr(e)).map((e) => Tr(e)).reduce((e, t) => {
      const { outerHTML: s } = t, i = s in e ? e[s] : {
        type: br(t),
        tracked: vr(t),
        elements: []
      };
      return Object.assign(Object.assign({}, e), { [s]: Object.assign(Object.assign({}, i), { elements: [...i.elements, t] }) });
    }, {});
  }
  get trackedElementSignature() {
    return Object.keys(this.detailsByOuterHTML).filter((e) => this.detailsByOuterHTML[e].tracked).join("");
  }
  getScriptElementsNotInSnapshot(e) {
    return this.getElementsMatchingTypeNotInSnapshot("script", e);
  }
  getStylesheetElementsNotInSnapshot(e) {
    return this.getElementsMatchingTypeNotInSnapshot("stylesheet", e);
  }
  getElementsMatchingTypeNotInSnapshot(e, t) {
    return Object.keys(this.detailsByOuterHTML).filter((s) => !(s in t.detailsByOuterHTML)).map((s) => this.detailsByOuterHTML[s]).filter(({ type: s }) => s == e).map(({ elements: [s] }) => s);
  }
  get provisionalElements() {
    return Object.keys(this.detailsByOuterHTML).reduce((e, t) => {
      const { type: s, tracked: i, elements: n } = this.detailsByOuterHTML[t];
      return s == null && !i ? [...e, ...n] : n.length > 1 ? [...e, ...n.slice(1)] : e;
    }, []);
  }
  getMetaValue(e) {
    const t = this.findMetaElementByName(e);
    return t ? t.getAttribute("content") : null;
  }
  findMetaElementByName(e) {
    return Object.keys(this.detailsByOuterHTML).reduce((t, s) => {
      const { elements: [i] } = this.detailsByOuterHTML[s];
      return Sr(i, e) ? i : t;
    }, void 0);
  }
}
function br(r) {
  if (yr(r))
    return "script";
  if (Er(r))
    return "stylesheet";
}
function vr(r) {
  return r.getAttribute("data-turbo-track") == "reload";
}
function yr(r) {
  return r.tagName.toLowerCase() == "script";
}
function wr(r) {
  return r.tagName.toLowerCase() == "noscript";
}
function Er(r) {
  const e = r.tagName.toLowerCase();
  return e == "style" || e == "link" && r.getAttribute("rel") == "stylesheet";
}
function Sr(r, e) {
  return r.tagName.toLowerCase() == "meta" && r.getAttribute("name") == e;
}
function Tr(r) {
  return r.hasAttribute("nonce") && r.setAttribute("nonce", ""), r;
}
class I extends te {
  constructor(e, t) {
    super(e), this.headSnapshot = t;
  }
  static fromHTMLString(e = "") {
    return this.fromDocument(Ue(e));
  }
  static fromElement(e) {
    return this.fromDocument(e.ownerDocument);
  }
  static fromDocument({ head: e, body: t }) {
    return new this(t, new gr(e));
  }
  clone() {
    return new I(this.element.cloneNode(!0), this.headSnapshot);
  }
  get headElement() {
    return this.headSnapshot.element;
  }
  get rootLocation() {
    var e;
    const t = (e = this.getSetting("root")) !== null && e !== void 0 ? e : "/";
    return S(t);
  }
  get cacheControlValue() {
    return this.getSetting("cache-control");
  }
  get isPreviewable() {
    return this.cacheControlValue != "no-preview";
  }
  get isCacheable() {
    return this.cacheControlValue != "no-cache";
  }
  get isVisitable() {
    return this.getSetting("visit-control") != "reload";
  }
  getSetting(e) {
    return this.headSnapshot.getMetaValue(`turbo-${e}`);
  }
}
var $;
(function(r) {
  r.visitStart = "visitStart", r.requestStart = "requestStart", r.requestEnd = "requestEnd", r.visitEnd = "visitEnd";
})($ || ($ = {}));
var k;
(function(r) {
  r.initialized = "initialized", r.started = "started", r.canceled = "canceled", r.failed = "failed", r.completed = "completed";
})(k || (k = {}));
const Ar = {
  action: "advance",
  historyChanged: !1,
  visitCachedSnapshot: () => {
  },
  willRender: !0
};
var B;
(function(r) {
  r[r.networkFailure = 0] = "networkFailure", r[r.timeoutFailure = -1] = "timeoutFailure", r[r.contentTypeMismatch = -2] = "contentTypeMismatch";
})(B || (B = {}));
class Cr {
  constructor(e, t, s, i = {}) {
    this.identifier = K(), this.timingMetrics = {}, this.followedRedirect = !1, this.historyChanged = !1, this.scrolled = !1, this.snapshotCached = !1, this.state = k.initialized, this.delegate = e, this.location = t, this.restorationIdentifier = s || K();
    const { action: n, historyChanged: o, referrer: c, snapshotHTML: a, response: l, visitCachedSnapshot: d, willRender: p } = Object.assign(Object.assign({}, Ar), i);
    this.action = n, this.historyChanged = o, this.referrer = c, this.snapshotHTML = a, this.response = l, this.isSamePage = this.delegate.locationWithActionIsSamePage(this.location, this.action), this.visitCachedSnapshot = d, this.willRender = p, this.scrolled = !p;
  }
  get adapter() {
    return this.delegate.adapter;
  }
  get view() {
    return this.delegate.view;
  }
  get history() {
    return this.delegate.history;
  }
  get restorationData() {
    return this.history.getRestorationDataForIdentifier(this.restorationIdentifier);
  }
  get silent() {
    return this.isSamePage;
  }
  start() {
    this.state == k.initialized && (this.recordTimingMetric($.visitStart), this.state = k.started, this.adapter.visitStarted(this), this.delegate.visitStarted(this));
  }
  cancel() {
    this.state == k.started && (this.request && this.request.cancel(), this.cancelRender(), this.state = k.canceled);
  }
  complete() {
    this.state == k.started && (this.recordTimingMetric($.visitEnd), this.state = k.completed, this.adapter.visitCompleted(this), this.delegate.visitCompleted(this), this.followRedirect());
  }
  fail() {
    this.state == k.started && (this.state = k.failed, this.adapter.visitFailed(this));
  }
  changeHistory() {
    var e;
    if (!this.historyChanged) {
      const t = this.location.href === ((e = this.referrer) === null || e === void 0 ? void 0 : e.href) ? "replace" : this.action, s = this.getHistoryMethodForAction(t);
      this.history.update(s, this.location, this.restorationIdentifier), this.historyChanged = !0;
    }
  }
  issueRequest() {
    this.hasPreloadedResponse() ? this.simulateRequest() : this.shouldIssueRequest() && !this.request && (this.request = new fe(this, w.get, this.location), this.request.perform());
  }
  simulateRequest() {
    this.response && (this.startRequest(), this.recordResponse(), this.finishRequest());
  }
  startRequest() {
    this.recordTimingMetric($.requestStart), this.adapter.visitRequestStarted(this);
  }
  recordResponse(e = this.response) {
    if (this.response = e, e) {
      const { statusCode: t } = e;
      Ce(t) ? this.adapter.visitRequestCompleted(this) : this.adapter.visitRequestFailedWithStatusCode(this, t);
    }
  }
  finishRequest() {
    this.recordTimingMetric($.requestEnd), this.adapter.visitRequestFinished(this);
  }
  loadResponse() {
    if (this.response) {
      const { statusCode: e, responseHTML: t } = this.response;
      this.render(async () => {
        this.cacheSnapshot(), this.view.renderPromise && await this.view.renderPromise, Ce(e) && t != null ? (await this.view.renderPage(I.fromHTMLString(t), !1, this.willRender), this.adapter.visitRendered(this), this.complete()) : (await this.view.renderError(I.fromHTMLString(t)), this.adapter.visitRendered(this), this.fail());
      });
    }
  }
  getCachedSnapshot() {
    const e = this.view.getCachedSnapshotForLocation(this.location) || this.getPreloadedSnapshot();
    if (e && (!H(this.location) || e.hasAnchor(H(this.location))) && (this.action == "restore" || e.isPreviewable))
      return e;
  }
  getPreloadedSnapshot() {
    if (this.snapshotHTML)
      return I.fromHTMLString(this.snapshotHTML);
  }
  hasCachedSnapshot() {
    return this.getCachedSnapshot() != null;
  }
  loadCachedSnapshot() {
    const e = this.getCachedSnapshot();
    if (e) {
      const t = this.shouldIssueRequest();
      this.render(async () => {
        this.cacheSnapshot(), this.isSamePage ? this.adapter.visitRendered(this) : (this.view.renderPromise && await this.view.renderPromise, await this.view.renderPage(e, t, this.willRender), this.adapter.visitRendered(this), t || this.complete());
      });
    }
  }
  followRedirect() {
    var e;
    this.redirectedToLocation && !this.followedRedirect && (!((e = this.response) === null || e === void 0) && e.redirected) && (this.adapter.visitProposedToLocation(this.redirectedToLocation, {
      action: "replace",
      response: this.response
    }), this.followedRedirect = !0);
  }
  goToSamePageAnchor() {
    this.isSamePage && this.render(async () => {
      this.cacheSnapshot(), this.adapter.visitRendered(this);
    });
  }
  requestStarted() {
    this.startRequest();
  }
  requestPreventedHandlingResponse(e, t) {
  }
  async requestSucceededWithResponse(e, t) {
    const s = await t.responseHTML, { redirected: i, statusCode: n } = t;
    s == null ? this.recordResponse({ statusCode: B.contentTypeMismatch, redirected: i }) : (this.redirectedToLocation = t.redirected ? t.location : void 0, this.recordResponse({ statusCode: n, responseHTML: s, redirected: i }));
  }
  async requestFailedWithResponse(e, t) {
    const s = await t.responseHTML, { redirected: i, statusCode: n } = t;
    s == null ? this.recordResponse({ statusCode: B.contentTypeMismatch, redirected: i }) : this.recordResponse({ statusCode: n, responseHTML: s, redirected: i });
  }
  requestErrored(e, t) {
    this.recordResponse({ statusCode: B.networkFailure, redirected: !1 });
  }
  requestFinished() {
    this.finishRequest();
  }
  performScroll() {
    this.scrolled || (this.action == "restore" ? this.scrollToRestoredPosition() || this.scrollToAnchor() || this.view.scrollToTop() : this.scrollToAnchor() || this.view.scrollToTop(), this.isSamePage && this.delegate.visitScrolledToSamePageLocation(this.view.lastRenderedLocation, this.location), this.scrolled = !0);
  }
  scrollToRestoredPosition() {
    const { scrollPosition: e } = this.restorationData;
    if (e)
      return this.view.scrollToPosition(e), !0;
  }
  scrollToAnchor() {
    const e = H(this.location);
    if (e != null)
      return this.view.scrollToAnchor(e), !0;
  }
  recordTimingMetric(e) {
    this.timingMetrics[e] = (/* @__PURE__ */ new Date()).getTime();
  }
  getTimingMetrics() {
    return Object.assign({}, this.timingMetrics);
  }
  getHistoryMethodForAction(e) {
    switch (e) {
      case "replace":
        return history.replaceState;
      case "advance":
      case "restore":
        return history.pushState;
    }
  }
  hasPreloadedResponse() {
    return typeof this.response == "object";
  }
  shouldIssueRequest() {
    return this.isSamePage ? !1 : this.action == "restore" ? !this.hasCachedSnapshot() : this.willRender;
  }
  cacheSnapshot() {
    this.snapshotCached || (this.view.cacheSnapshot().then((e) => e && this.visitCachedSnapshot(e)), this.snapshotCached = !0);
  }
  async render(e) {
    this.cancelRender(), await new Promise((t) => {
      this.frame = requestAnimationFrame(() => t());
    }), await e(), delete this.frame, this.performScroll();
  }
  cancelRender() {
    this.frame && (cancelAnimationFrame(this.frame), delete this.frame);
  }
}
function Ce(r) {
  return r >= 200 && r < 300;
}
class kr {
  constructor(e) {
    this.progressBar = new R(), this.showProgressBar = () => {
      this.progressBar.show();
    }, this.session = e;
  }
  visitProposedToLocation(e, t) {
    this.navigator.startVisit(e, K(), t);
  }
  visitStarted(e) {
    e.loadCachedSnapshot(), e.issueRequest(), e.changeHistory(), e.goToSamePageAnchor();
  }
  visitRequestStarted(e) {
    this.progressBar.setValue(0), e.hasCachedSnapshot() || e.action != "restore" ? this.showVisitProgressBarAfterDelay() : this.showProgressBar();
  }
  visitRequestCompleted(e) {
    e.loadResponse();
  }
  visitRequestFailedWithStatusCode(e, t) {
    switch (t) {
      case B.networkFailure:
      case B.timeoutFailure:
      case B.contentTypeMismatch:
        return this.reload();
      default:
        return e.loadResponse();
    }
  }
  visitRequestFinished(e) {
    this.progressBar.setValue(1), this.hideVisitProgressBar();
  }
  visitCompleted(e) {
  }
  pageInvalidated() {
    this.reload();
  }
  visitFailed(e) {
  }
  visitRendered(e) {
  }
  formSubmissionStarted(e) {
    this.progressBar.setValue(0), this.showFormProgressBarAfterDelay();
  }
  formSubmissionFinished(e) {
    this.progressBar.setValue(1), this.hideFormProgressBar();
  }
  showVisitProgressBarAfterDelay() {
    this.visitProgressBarTimeout = window.setTimeout(this.showProgressBar, this.session.progressBarDelay);
  }
  hideVisitProgressBar() {
    this.progressBar.hide(), this.visitProgressBarTimeout != null && (window.clearTimeout(this.visitProgressBarTimeout), delete this.visitProgressBarTimeout);
  }
  showFormProgressBarAfterDelay() {
    this.formProgressBarTimeout == null && (this.formProgressBarTimeout = window.setTimeout(this.showProgressBar, this.session.progressBarDelay));
  }
  hideFormProgressBar() {
    this.progressBar.hide(), this.formProgressBarTimeout != null && (window.clearTimeout(this.formProgressBarTimeout), delete this.formProgressBarTimeout);
  }
  reload() {
    window.location.reload();
  }
  get navigator() {
    return this.session.navigator;
  }
}
class Lr {
  constructor() {
    this.started = !1;
  }
  start() {
    this.started || (this.started = !0, addEventListener("turbo:before-cache", this.removeStaleElements, !1));
  }
  stop() {
    this.started && (this.started = !1, removeEventListener("turbo:before-cache", this.removeStaleElements, !1));
  }
  removeStaleElements() {
    const e = [...document.querySelectorAll('[data-turbo-cache="false"]')];
    for (const t of e)
      t.remove();
  }
}
class Mr {
  constructor(e) {
    this.started = !1, this.submitCaptured = () => {
      removeEventListener("submit", this.submitBubbled, !1), addEventListener("submit", this.submitBubbled, !1);
    }, this.submitBubbled = (t) => {
      if (!t.defaultPrevented) {
        const s = t.target instanceof HTMLFormElement ? t.target : void 0, i = t.submitter || void 0;
        s && ((i == null ? void 0 : i.getAttribute("formmethod")) || s.getAttribute("method")) != "dialog" && this.delegate.willSubmitForm(s, i) && (t.preventDefault(), this.delegate.formSubmitted(s, i));
      }
    }, this.delegate = e;
  }
  start() {
    this.started || (addEventListener("submit", this.submitCaptured, !0), this.started = !0);
  }
  stop() {
    this.started && (removeEventListener("submit", this.submitCaptured, !0), this.started = !1);
  }
}
class Rr {
  constructor(e) {
    this.element = e, this.linkInterceptor = new ze(this, e), this.formInterceptor = new We(this, e);
  }
  start() {
    this.linkInterceptor.start(), this.formInterceptor.start();
  }
  stop() {
    this.linkInterceptor.stop(), this.formInterceptor.stop();
  }
  shouldInterceptLinkClick(e, t) {
    return this.shouldRedirect(e);
  }
  linkClickIntercepted(e, t) {
    const s = this.findFrameElement(e);
    s && s.delegate.linkClickIntercepted(e, t);
  }
  shouldInterceptFormSubmission(e, t) {
    return this.shouldSubmit(e, t);
  }
  formSubmissionIntercepted(e, t) {
    const s = this.findFrameElement(e, t);
    s && (s.removeAttribute("reloadable"), s.delegate.formSubmissionIntercepted(e, t));
  }
  shouldSubmit(e, t) {
    var s;
    const i = pe(e, t), n = this.element.ownerDocument.querySelector('meta[name="turbo-root"]'), o = S((s = n == null ? void 0 : n.content) !== null && s !== void 0 ? s : "/");
    return this.shouldRedirect(e, t) && W(i, o);
  }
  shouldRedirect(e, t) {
    const s = this.findFrameElement(e, t);
    return s ? s != e.closest("turbo-frame") : !1;
  }
  findFrameElement(e, t) {
    const s = (t == null ? void 0 : t.getAttribute("data-turbo-frame")) || e.getAttribute("data-turbo-frame");
    if (s && s != "_top") {
      const i = this.element.querySelector(`#${s}:not([disabled])`);
      if (i instanceof D)
        return i;
    }
  }
}
class Or {
  constructor(e) {
    this.restorationIdentifier = K(), this.restorationData = {}, this.started = !1, this.pageLoaded = !1, this.onPopState = (t) => {
      if (this.shouldHandlePopState()) {
        const { turbo: s } = t.state || {};
        if (s) {
          this.location = new URL(window.location.href);
          const { restorationIdentifier: i } = s;
          this.restorationIdentifier = i, this.delegate.historyPoppedToLocationWithRestorationIdentifier(this.location, i);
        }
      }
    }, this.onPageLoad = async (t) => {
      await er(), this.pageLoaded = !0;
    }, this.delegate = e;
  }
  start() {
    this.started || (addEventListener("popstate", this.onPopState, !1), addEventListener("load", this.onPageLoad, !1), this.started = !0, this.replace(new URL(window.location.href)));
  }
  stop() {
    this.started && (removeEventListener("popstate", this.onPopState, !1), removeEventListener("load", this.onPageLoad, !1), this.started = !1);
  }
  push(e, t) {
    this.update(history.pushState, e, t);
  }
  replace(e, t) {
    this.update(history.replaceState, e, t);
  }
  update(e, t, s = K()) {
    const i = { turbo: { restorationIdentifier: s } };
    e.call(history, i, "", t.href), this.location = t, this.restorationIdentifier = s;
  }
  getRestorationDataForIdentifier(e) {
    return this.restorationData[e] || {};
  }
  updateRestorationData(e) {
    const { restorationIdentifier: t } = this, s = this.restorationData[t];
    this.restorationData[t] = Object.assign(Object.assign({}, s), e);
  }
  assumeControlOfScrollRestoration() {
    var e;
    this.previousScrollRestoration || (this.previousScrollRestoration = (e = history.scrollRestoration) !== null && e !== void 0 ? e : "auto", history.scrollRestoration = "manual");
  }
  relinquishControlOfScrollRestoration() {
    this.previousScrollRestoration && (history.scrollRestoration = this.previousScrollRestoration, delete this.previousScrollRestoration);
  }
  shouldHandlePopState() {
    return this.pageIsLoaded();
  }
  pageIsLoaded() {
    return this.pageLoaded || document.readyState == "complete";
  }
}
class Fr {
  constructor(e) {
    this.started = !1, this.clickCaptured = () => {
      removeEventListener("click", this.clickBubbled, !1), addEventListener("click", this.clickBubbled, !1);
    }, this.clickBubbled = (t) => {
      if (this.clickEventIsSignificant(t)) {
        const s = t.composedPath && t.composedPath()[0] || t.target, i = this.findLinkFromClickTarget(s);
        if (i) {
          const n = this.getLocationForLink(i);
          this.delegate.willFollowLinkToLocation(i, n) && (t.preventDefault(), this.delegate.followedLinkToLocation(i, n));
        }
      }
    }, this.delegate = e;
  }
  start() {
    this.started || (addEventListener("click", this.clickCaptured, !0), this.started = !0);
  }
  stop() {
    this.started && (removeEventListener("click", this.clickCaptured, !0), this.started = !1);
  }
  clickEventIsSignificant(e) {
    return !(e.target && e.target.isContentEditable || e.defaultPrevented || e.which > 1 || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey);
  }
  findLinkFromClickTarget(e) {
    if (e instanceof Element)
      return e.closest("a[href]:not([target^=_]):not([download])");
  }
  getLocationForLink(e) {
    return S(e.getAttribute("href") || "");
  }
}
function be(r) {
  return r == "advance" || r == "replace" || r == "restore";
}
class Pr {
  constructor(e) {
    this.delegate = e;
  }
  proposeVisit(e, t = {}) {
    this.delegate.allowsVisitingLocationWithAction(e, t.action) && (W(e, this.view.snapshot.rootLocation) ? this.delegate.visitProposedToLocation(e, t) : window.location.href = e.toString());
  }
  startVisit(e, t, s = {}) {
    this.stop(), this.currentVisit = new Cr(this, S(e), t, Object.assign({ referrer: this.location }, s)), this.currentVisit.start();
  }
  submitForm(e, t) {
    this.stop(), this.formSubmission = new Q(this, e, t, !0), this.formSubmission.start();
  }
  stop() {
    this.formSubmission && (this.formSubmission.stop(), delete this.formSubmission), this.currentVisit && (this.currentVisit.cancel(), delete this.currentVisit);
  }
  get adapter() {
    return this.delegate.adapter;
  }
  get view() {
    return this.delegate.view;
  }
  get history() {
    return this.delegate.history;
  }
  formSubmissionStarted(e) {
    typeof this.adapter.formSubmissionStarted == "function" && this.adapter.formSubmissionStarted(e);
  }
  async formSubmissionSucceededWithResponse(e, t) {
    if (e == this.formSubmission) {
      const s = await t.responseHTML;
      if (s) {
        e.method != w.get && this.view.clearSnapshotCache();
        const { statusCode: i, redirected: n } = t, c = { action: this.getActionForFormSubmission(e), response: { statusCode: i, responseHTML: s, redirected: n } };
        this.proposeVisit(t.location, c);
      }
    }
  }
  async formSubmissionFailedWithResponse(e, t) {
    const s = await t.responseHTML;
    if (s) {
      const i = I.fromHTMLString(s);
      t.serverError ? await this.view.renderError(i) : await this.view.renderPage(i), this.view.scrollToTop(), this.view.clearSnapshotCache();
    }
  }
  formSubmissionErrored(e, t) {
    console.error(t);
  }
  formSubmissionFinished(e) {
    typeof this.adapter.formSubmissionFinished == "function" && this.adapter.formSubmissionFinished(e);
  }
  visitStarted(e) {
    this.delegate.visitStarted(e);
  }
  visitCompleted(e) {
    this.delegate.visitCompleted(e);
  }
  locationWithActionIsSamePage(e, t) {
    const s = H(e), i = H(this.view.lastRenderedLocation), n = t === "restore" && typeof s > "u";
    return t !== "replace" && ne(e) === ne(this.view.lastRenderedLocation) && (n || s != null && s !== i);
  }
  visitScrolledToSamePageLocation(e, t) {
    this.delegate.visitScrolledToSamePageLocation(e, t);
  }
  get location() {
    return this.history.location;
  }
  get restorationIdentifier() {
    return this.history.restorationIdentifier;
  }
  getActionForFormSubmission(e) {
    const { formElement: t, submitter: s } = e, i = ee("data-turbo-action", s, t);
    return be(i) ? i : "advance";
  }
}
var M;
(function(r) {
  r[r.initial = 0] = "initial", r[r.loading = 1] = "loading", r[r.interactive = 2] = "interactive", r[r.complete = 3] = "complete";
})(M || (M = {}));
class xr {
  constructor(e) {
    this.stage = M.initial, this.started = !1, this.interpretReadyState = () => {
      const { readyState: t } = this;
      t == "interactive" ? this.pageIsInteractive() : t == "complete" && this.pageIsComplete();
    }, this.pageWillUnload = () => {
      this.delegate.pageWillUnload();
    }, this.delegate = e;
  }
  start() {
    this.started || (this.stage == M.initial && (this.stage = M.loading), document.addEventListener("readystatechange", this.interpretReadyState, !1), addEventListener("pagehide", this.pageWillUnload, !1), this.started = !0);
  }
  stop() {
    this.started && (document.removeEventListener("readystatechange", this.interpretReadyState, !1), removeEventListener("pagehide", this.pageWillUnload, !1), this.started = !1);
  }
  pageIsInteractive() {
    this.stage == M.loading && (this.stage = M.interactive, this.delegate.pageBecameInteractive());
  }
  pageIsComplete() {
    this.pageIsInteractive(), this.stage == M.interactive && (this.stage = M.complete, this.delegate.pageLoaded());
  }
  get readyState() {
    return document.readyState;
  }
}
class Ir {
  constructor(e) {
    this.started = !1, this.onScroll = () => {
      this.updatePosition({ x: window.pageXOffset, y: window.pageYOffset });
    }, this.delegate = e;
  }
  start() {
    this.started || (addEventListener("scroll", this.onScroll, !1), this.onScroll(), this.started = !0);
  }
  stop() {
    this.started && (removeEventListener("scroll", this.onScroll, !1), this.started = !1);
  }
  updatePosition(e) {
    this.delegate.scrollPositionChanged(e);
  }
}
class Br {
  constructor(e) {
    this.sources = /* @__PURE__ */ new Set(), this.started = !1, this.inspectFetchResponse = (t) => {
      const s = Dr(t);
      s && Nr(s) && (t.preventDefault(), this.receiveMessageResponse(s));
    }, this.receiveMessageEvent = (t) => {
      this.started && typeof t.data == "string" && this.receiveMessageHTML(t.data);
    }, this.delegate = e;
  }
  start() {
    this.started || (this.started = !0, addEventListener("turbo:before-fetch-response", this.inspectFetchResponse, !1));
  }
  stop() {
    this.started && (this.started = !1, removeEventListener("turbo:before-fetch-response", this.inspectFetchResponse, !1));
  }
  connectStreamSource(e) {
    this.streamSourceIsConnected(e) || (this.sources.add(e), e.addEventListener("message", this.receiveMessageEvent, !1));
  }
  disconnectStreamSource(e) {
    this.streamSourceIsConnected(e) && (this.sources.delete(e), e.removeEventListener("message", this.receiveMessageEvent, !1));
  }
  streamSourceIsConnected(e) {
    return this.sources.has(e);
  }
  async receiveMessageResponse(e) {
    const t = await e.responseHTML;
    t && this.receiveMessageHTML(t);
  }
  receiveMessageHTML(e) {
    this.delegate.receivedMessageFromStream(new X(e));
  }
}
function Dr(r) {
  var e;
  const t = (e = r.detail) === null || e === void 0 ? void 0 : e.fetchResponse;
  if (t instanceof Ve)
    return t;
}
function Nr(r) {
  var e;
  return ((e = r.contentType) !== null && e !== void 0 ? e : "").startsWith(X.contentType);
}
class qr extends ge {
  async render() {
    this.replaceHeadAndBody(), this.activateScriptElements();
  }
  replaceHeadAndBody() {
    const { documentElement: e, head: t, body: s } = document;
    e.replaceChild(this.newHead, t), e.replaceChild(this.newElement, s);
  }
  activateScriptElements() {
    for (const e of this.scriptElements) {
      const t = e.parentNode;
      if (t) {
        const s = this.createScriptElement(e);
        t.replaceChild(s, e);
      }
    }
  }
  get newHead() {
    return this.newSnapshot.headSnapshot.element;
  }
  get scriptElements() {
    return [...document.documentElement.querySelectorAll("script")];
  }
}
class Je extends ge {
  get shouldRender() {
    return this.newSnapshot.isVisitable && this.trackedElementsAreIdentical;
  }
  prepareToRender() {
    this.mergeHead();
  }
  async render() {
    this.willRender && this.replaceBody();
  }
  finishRendering() {
    super.finishRendering(), this.isPreview || this.focusFirstAutofocusableElement();
  }
  get currentHeadSnapshot() {
    return this.currentSnapshot.headSnapshot;
  }
  get newHeadSnapshot() {
    return this.newSnapshot.headSnapshot;
  }
  get newElement() {
    return this.newSnapshot.element;
  }
  mergeHead() {
    this.copyNewHeadStylesheetElements(), this.copyNewHeadScriptElements(), this.removeCurrentHeadProvisionalElements(), this.copyNewHeadProvisionalElements();
  }
  replaceBody() {
    this.preservingPermanentElements(() => {
      this.activateNewBody(), this.assignNewBody();
    });
  }
  get trackedElementsAreIdentical() {
    return this.currentHeadSnapshot.trackedElementSignature == this.newHeadSnapshot.trackedElementSignature;
  }
  copyNewHeadStylesheetElements() {
    for (const e of this.newHeadStylesheetElements)
      document.head.appendChild(e);
  }
  copyNewHeadScriptElements() {
    for (const e of this.newHeadScriptElements)
      document.head.appendChild(this.createScriptElement(e));
  }
  removeCurrentHeadProvisionalElements() {
    for (const e of this.currentHeadProvisionalElements)
      document.head.removeChild(e);
  }
  copyNewHeadProvisionalElements() {
    for (const e of this.newHeadProvisionalElements)
      document.head.appendChild(e);
  }
  activateNewBody() {
    document.adoptNode(this.newElement), this.activateNewBodyScriptElements();
  }
  activateNewBodyScriptElements() {
    for (const e of this.newBodyScriptElements) {
      const t = this.createScriptElement(e);
      e.replaceWith(t);
    }
  }
  assignNewBody() {
    document.body && this.newElement instanceof HTMLBodyElement ? document.body.replaceWith(this.newElement) : document.documentElement.appendChild(this.newElement);
  }
  get newHeadStylesheetElements() {
    return this.newHeadSnapshot.getStylesheetElementsNotInSnapshot(this.currentHeadSnapshot);
  }
  get newHeadScriptElements() {
    return this.newHeadSnapshot.getScriptElementsNotInSnapshot(this.currentHeadSnapshot);
  }
  get currentHeadProvisionalElements() {
    return this.currentHeadSnapshot.provisionalElements;
  }
  get newHeadProvisionalElements() {
    return this.newHeadSnapshot.provisionalElements;
  }
  get newBodyScriptElements() {
    return this.newElement.querySelectorAll("script");
  }
}
class Hr {
  constructor(e) {
    this.keys = [], this.snapshots = {}, this.size = e;
  }
  has(e) {
    return Z(e) in this.snapshots;
  }
  get(e) {
    if (this.has(e)) {
      const t = this.read(e);
      return this.touch(e), t;
    }
  }
  put(e, t) {
    return this.write(e, t), this.touch(e), t;
  }
  clear() {
    this.snapshots = {};
  }
  read(e) {
    return this.snapshots[Z(e)];
  }
  write(e, t) {
    this.snapshots[Z(e)] = t;
  }
  touch(e) {
    const t = Z(e), s = this.keys.indexOf(t);
    s > -1 && this.keys.splice(s, 1), this.keys.unshift(t), this.trim();
  }
  trim() {
    for (const e of this.keys.splice(this.size))
      delete this.snapshots[e];
  }
}
class jr extends Ke {
  constructor() {
    super(...arguments), this.snapshotCache = new Hr(10), this.lastRenderedLocation = new URL(location.href);
  }
  renderPage(e, t = !1, s = !0) {
    const i = new Je(this.snapshot, e, t, s);
    return this.render(i);
  }
  renderError(e) {
    const t = new qr(this.snapshot, e, !1);
    return this.render(t);
  }
  clearSnapshotCache() {
    this.snapshotCache.clear();
  }
  async cacheSnapshot() {
    if (this.shouldCacheSnapshot) {
      this.delegate.viewWillCacheSnapshot();
      const { snapshot: e, lastRenderedLocation: t } = this;
      await Ys();
      const s = e.clone();
      return this.snapshotCache.put(t, s), s;
    }
  }
  getCachedSnapshotForLocation(e) {
    return this.snapshotCache.get(e);
  }
  get snapshot() {
    return I.fromElement(this.element);
  }
  get shouldCacheSnapshot() {
    return this.snapshot.isCacheable;
  }
}
class $r {
  constructor() {
    this.navigator = new Pr(this), this.history = new Or(this), this.view = new jr(this, document.documentElement), this.adapter = new kr(this), this.pageObserver = new xr(this), this.cacheObserver = new Lr(), this.linkClickObserver = new Fr(this), this.formSubmitObserver = new Mr(this), this.scrollObserver = new Ir(this), this.streamObserver = new Br(this), this.frameRedirector = new Rr(document.documentElement), this.drive = !0, this.enabled = !0, this.progressBarDelay = 500, this.started = !1;
  }
  start() {
    this.started || (this.pageObserver.start(), this.cacheObserver.start(), this.linkClickObserver.start(), this.formSubmitObserver.start(), this.scrollObserver.start(), this.streamObserver.start(), this.frameRedirector.start(), this.history.start(), this.started = !0, this.enabled = !0);
  }
  disable() {
    this.enabled = !1;
  }
  stop() {
    this.started && (this.pageObserver.stop(), this.cacheObserver.stop(), this.linkClickObserver.stop(), this.formSubmitObserver.stop(), this.scrollObserver.stop(), this.streamObserver.stop(), this.frameRedirector.stop(), this.history.stop(), this.started = !1);
  }
  registerAdapter(e) {
    this.adapter = e;
  }
  visit(e, t = {}) {
    this.navigator.proposeVisit(S(e), t);
  }
  connectStreamSource(e) {
    this.streamObserver.connectStreamSource(e);
  }
  disconnectStreamSource(e) {
    this.streamObserver.disconnectStreamSource(e);
  }
  renderStreamMessage(e) {
    document.documentElement.appendChild(X.wrap(e).fragment);
  }
  clearCache() {
    this.view.clearSnapshotCache();
  }
  setProgressBarDelay(e) {
    this.progressBarDelay = e;
  }
  get location() {
    return this.history.location;
  }
  get restorationIdentifier() {
    return this.history.restorationIdentifier;
  }
  historyPoppedToLocationWithRestorationIdentifier(e, t) {
    this.enabled ? this.navigator.startVisit(e, t, { action: "restore", historyChanged: !0 }) : this.adapter.pageInvalidated();
  }
  scrollPositionChanged(e) {
    this.history.updateRestorationData({ scrollPosition: e });
  }
  willFollowLinkToLocation(e, t) {
    return this.elementDriveEnabled(e) && W(t, this.snapshot.rootLocation) && this.applicationAllowsFollowingLinkToLocation(e, t);
  }
  followedLinkToLocation(e, t) {
    const s = this.getActionForLink(e);
    this.convertLinkWithMethodClickToFormSubmission(e) || this.visit(t.href, { action: s });
  }
  convertLinkWithMethodClickToFormSubmission(e) {
    const t = e.getAttribute("data-turbo-method");
    if (t) {
      const s = document.createElement("form");
      s.method = t, s.action = e.getAttribute("href") || "undefined", s.hidden = !0, e.hasAttribute("data-turbo-confirm") && s.setAttribute("data-turbo-confirm", e.getAttribute("data-turbo-confirm"));
      const i = this.getTargetFrameForLink(e);
      return i ? (s.setAttribute("data-turbo-frame", i), s.addEventListener("turbo:submit-start", () => s.remove())) : s.addEventListener("submit", () => s.remove()), document.body.appendChild(s), A("submit", { cancelable: !0, target: s });
    } else
      return !1;
  }
  allowsVisitingLocationWithAction(e, t) {
    return this.locationWithActionIsSamePage(e, t) || this.applicationAllowsVisitingLocation(e);
  }
  visitProposedToLocation(e, t) {
    ke(e), this.adapter.visitProposedToLocation(e, t);
  }
  visitStarted(e) {
    ke(e.location), e.silent || this.notifyApplicationAfterVisitingLocation(e.location, e.action);
  }
  visitCompleted(e) {
    this.notifyApplicationAfterPageLoad(e.getTimingMetrics());
  }
  locationWithActionIsSamePage(e, t) {
    return this.navigator.locationWithActionIsSamePage(e, t);
  }
  visitScrolledToSamePageLocation(e, t) {
    this.notifyApplicationAfterVisitingSamePageLocation(e, t);
  }
  willSubmitForm(e, t) {
    const s = pe(e, t);
    return this.elementDriveEnabled(e) && (!t || this.elementDriveEnabled(t)) && W(S(s), this.snapshot.rootLocation);
  }
  formSubmitted(e, t) {
    this.navigator.submitForm(e, t);
  }
  pageBecameInteractive() {
    this.view.lastRenderedLocation = this.location, this.notifyApplicationAfterPageLoad();
  }
  pageLoaded() {
    this.history.assumeControlOfScrollRestoration();
  }
  pageWillUnload() {
    this.history.relinquishControlOfScrollRestoration();
  }
  receivedMessageFromStream(e) {
    this.renderStreamMessage(e);
  }
  viewWillCacheSnapshot() {
    var e;
    !((e = this.navigator.currentVisit) === null || e === void 0) && e.silent || this.notifyApplicationBeforeCachingSnapshot();
  }
  allowsImmediateRender({ element: e }, t) {
    return !this.notifyApplicationBeforeRender(e, t).defaultPrevented;
  }
  viewRenderedSnapshot(e, t) {
    this.view.lastRenderedLocation = this.history.location, this.notifyApplicationAfterRender();
  }
  viewInvalidated() {
    this.adapter.pageInvalidated();
  }
  frameLoaded(e) {
    this.notifyApplicationAfterFrameLoad(e);
  }
  frameRendered(e, t) {
    this.notifyApplicationAfterFrameRender(e, t);
  }
  applicationAllowsFollowingLinkToLocation(e, t) {
    return !this.notifyApplicationAfterClickingLinkToLocation(e, t).defaultPrevented;
  }
  applicationAllowsVisitingLocation(e) {
    return !this.notifyApplicationBeforeVisitingLocation(e).defaultPrevented;
  }
  notifyApplicationAfterClickingLinkToLocation(e, t) {
    return A("turbo:click", { target: e, detail: { url: t.href }, cancelable: !0 });
  }
  notifyApplicationBeforeVisitingLocation(e) {
    return A("turbo:before-visit", { detail: { url: e.href }, cancelable: !0 });
  }
  notifyApplicationAfterVisitingLocation(e, t) {
    return oe(document.documentElement), A("turbo:visit", { detail: { url: e.href, action: t } });
  }
  notifyApplicationBeforeCachingSnapshot() {
    return A("turbo:before-cache");
  }
  notifyApplicationBeforeRender(e, t) {
    return A("turbo:before-render", { detail: { newBody: e, resume: t }, cancelable: !0 });
  }
  notifyApplicationAfterRender() {
    return A("turbo:render");
  }
  notifyApplicationAfterPageLoad(e = {}) {
    return ae(document.documentElement), A("turbo:load", { detail: { url: this.location.href, timing: e } });
  }
  notifyApplicationAfterVisitingSamePageLocation(e, t) {
    dispatchEvent(new HashChangeEvent("hashchange", { oldURL: e.toString(), newURL: t.toString() }));
  }
  notifyApplicationAfterFrameLoad(e) {
    return A("turbo:frame-load", { target: e });
  }
  notifyApplicationAfterFrameRender(e, t) {
    return A("turbo:frame-render", { detail: { fetchResponse: e }, target: t, cancelable: !0 });
  }
  elementDriveEnabled(e) {
    const t = e == null ? void 0 : e.closest("[data-turbo]");
    return this.drive ? t ? t.getAttribute("data-turbo") != "false" : !0 : t ? t.getAttribute("data-turbo") == "true" : !1;
  }
  getActionForLink(e) {
    const t = e.getAttribute("data-turbo-action");
    return be(t) ? t : "advance";
  }
  getTargetFrameForLink(e) {
    const t = e.getAttribute("data-turbo-frame");
    if (t)
      return t;
    {
      const s = e.closest("turbo-frame");
      if (s)
        return s.id;
    }
  }
  get snapshot() {
    return this.view.snapshot;
  }
}
function ke(r) {
  Object.defineProperties(r, Vr);
}
const Vr = {
  absoluteURL: {
    get() {
      return this.toString();
    }
  }
}, E = new $r(), { navigator: Ur } = E;
function Xe() {
  E.start();
}
function _r(r) {
  E.registerAdapter(r);
}
function Wr(r, e) {
  E.visit(r, e);
}
function Qe(r) {
  E.connectStreamSource(r);
}
function Ge(r) {
  E.disconnectStreamSource(r);
}
function Kr(r) {
  E.renderStreamMessage(r);
}
function zr() {
  E.clearCache();
}
function Jr(r) {
  E.setProgressBarDelay(r);
}
function Xr(r) {
  Q.confirmMethod = r;
}
var Qr = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  navigator: Ur,
  session: E,
  PageRenderer: Je,
  PageSnapshot: I,
  start: Xe,
  registerAdapter: _r,
  visit: Wr,
  connectStreamSource: Qe,
  disconnectStreamSource: Ge,
  renderStreamMessage: Kr,
  clearCache: zr,
  setProgressBarDelay: Jr,
  setConfirmMethod: Xr
});
class Gr {
  constructor(e) {
    this.fetchResponseLoaded = (t) => {
    }, this.currentFetchRequest = null, this.resolveVisitPromise = () => {
    }, this.connected = !1, this.hasBeenLoaded = !1, this.settingSourceURL = !1, this.element = e, this.view = new lr(this, this.element), this.appearanceObserver = new rr(this, this.element), this.linkInterceptor = new ze(this, this.element), this.formInterceptor = new We(this, this.element);
  }
  connect() {
    this.connected || (this.connected = !0, this.reloadable = !1, this.loadingStyle == x.lazy && this.appearanceObserver.start(), this.linkInterceptor.start(), this.formInterceptor.start(), this.sourceURLChanged());
  }
  disconnect() {
    this.connected && (this.connected = !1, this.appearanceObserver.stop(), this.linkInterceptor.stop(), this.formInterceptor.stop());
  }
  disabledChanged() {
    this.loadingStyle == x.eager && this.loadSourceURL();
  }
  sourceURLChanged() {
    (this.loadingStyle == x.eager || this.hasBeenLoaded) && this.loadSourceURL();
  }
  loadingStyleChanged() {
    this.loadingStyle == x.lazy ? this.appearanceObserver.start() : (this.appearanceObserver.stop(), this.loadSourceURL());
  }
  async loadSourceURL() {
    if (!this.settingSourceURL && this.enabled && this.isActive && (this.reloadable || this.sourceURL != this.currentURL)) {
      const e = this.currentURL;
      if (this.currentURL = this.sourceURL, this.sourceURL)
        try {
          this.element.loaded = this.visit(S(this.sourceURL)), this.appearanceObserver.stop(), await this.element.loaded, this.hasBeenLoaded = !0;
        } catch (t) {
          throw this.currentURL = e, t;
        }
    }
  }
  async loadResponse(e) {
    (e.redirected || e.succeeded && e.isHTML) && (this.sourceURL = e.response.url);
    try {
      const t = await e.responseHTML;
      if (t) {
        const { body: s } = Ue(t), i = new te(await this.extractForeignFrameElement(s)), n = new pr(this.view.snapshot, i, !1, !1);
        this.view.renderPromise && await this.view.renderPromise, await this.view.render(n), E.frameRendered(e, this.element), E.frameLoaded(this.element), this.fetchResponseLoaded(e);
      }
    } catch (t) {
      console.error(t), this.view.invalidate();
    } finally {
      this.fetchResponseLoaded = () => {
      };
    }
  }
  elementAppearedInViewport(e) {
    this.loadSourceURL();
  }
  shouldInterceptLinkClick(e, t) {
    return e.hasAttribute("data-turbo-method") ? !1 : this.shouldInterceptNavigation(e);
  }
  linkClickIntercepted(e, t) {
    this.reloadable = !0, this.navigateFrame(e, t);
  }
  shouldInterceptFormSubmission(e, t) {
    return this.shouldInterceptNavigation(e, t);
  }
  formSubmissionIntercepted(e, t) {
    this.formSubmission && this.formSubmission.stop(), this.reloadable = !1, this.formSubmission = new Q(this, e, t);
    const { fetchRequest: s } = this.formSubmission;
    this.prepareHeadersForRequest(s.headers, s), this.formSubmission.start();
  }
  prepareHeadersForRequest(e, t) {
    e["Turbo-Frame"] = this.id;
  }
  requestStarted(e) {
    oe(this.element);
  }
  requestPreventedHandlingResponse(e, t) {
    this.resolveVisitPromise();
  }
  async requestSucceededWithResponse(e, t) {
    await this.loadResponse(t), this.resolveVisitPromise();
  }
  requestFailedWithResponse(e, t) {
    console.error(t), this.resolveVisitPromise();
  }
  requestErrored(e, t) {
    console.error(t), this.resolveVisitPromise();
  }
  requestFinished(e) {
    ae(this.element);
  }
  formSubmissionStarted({ formElement: e }) {
    oe(e, this.findFrameElement(e));
  }
  formSubmissionSucceededWithResponse(e, t) {
    const s = this.findFrameElement(e.formElement, e.submitter);
    this.proposeVisitIfNavigatedWithAction(s, e.formElement, e.submitter), s.delegate.loadResponse(t);
  }
  formSubmissionFailedWithResponse(e, t) {
    this.element.delegate.loadResponse(t);
  }
  formSubmissionErrored(e, t) {
    console.error(t);
  }
  formSubmissionFinished({ formElement: e }) {
    ae(e, this.findFrameElement(e));
  }
  allowsImmediateRender(e, t) {
    return !0;
  }
  viewRenderedSnapshot(e, t) {
  }
  viewInvalidated() {
  }
  async visit(e) {
    var t;
    const s = new fe(this, w.get, e, new URLSearchParams(), this.element);
    return (t = this.currentFetchRequest) === null || t === void 0 || t.cancel(), this.currentFetchRequest = s, new Promise((i) => {
      this.resolveVisitPromise = () => {
        this.resolveVisitPromise = () => {
        }, this.currentFetchRequest = null, i();
      }, s.perform();
    });
  }
  navigateFrame(e, t, s) {
    const i = this.findFrameElement(e, s);
    this.proposeVisitIfNavigatedWithAction(i, e, s), i.setAttribute("reloadable", ""), i.src = t;
  }
  proposeVisitIfNavigatedWithAction(e, t, s) {
    const i = ee("data-turbo-action", s, t, e);
    if (be(i)) {
      const { visitCachedSnapshot: n } = new Zr(e);
      e.delegate.fetchResponseLoaded = (o) => {
        if (e.src) {
          const { statusCode: c, redirected: a } = o, l = e.ownerDocument.documentElement.outerHTML, d = { statusCode: c, redirected: a, responseHTML: l };
          E.visit(e.src, { action: i, response: d, visitCachedSnapshot: n, willRender: !1 });
        }
      };
    }
  }
  findFrameElement(e, t) {
    var s;
    const i = ee("data-turbo-frame", t, e) || this.element.getAttribute("target");
    return (s = Le(i)) !== null && s !== void 0 ? s : this.element;
  }
  async extractForeignFrameElement(e) {
    let t;
    const s = CSS.escape(this.id);
    try {
      if (t = Me(e.querySelector(`turbo-frame#${s}`), this.currentURL))
        return t;
      if (t = Me(e.querySelector(`turbo-frame[src][recurse~=${s}]`), this.currentURL))
        return await t.loaded, await this.extractForeignFrameElement(t);
      console.error(`Response has no matching <turbo-frame id="${s}"> element`);
    } catch (i) {
      console.error(i);
    }
    return new D();
  }
  formActionIsVisitable(e, t) {
    const s = pe(e, t);
    return W(S(s), this.rootLocation);
  }
  shouldInterceptNavigation(e, t) {
    const s = ee("data-turbo-frame", t, e) || this.element.getAttribute("target");
    if (e instanceof HTMLFormElement && !this.formActionIsVisitable(e, t) || !this.enabled || s == "_top")
      return !1;
    if (s) {
      const i = Le(s);
      if (i)
        return !i.disabled;
    }
    return !(!E.elementDriveEnabled(e) || t && !E.elementDriveEnabled(t));
  }
  get id() {
    return this.element.id;
  }
  get enabled() {
    return !this.element.disabled;
  }
  get sourceURL() {
    if (this.element.src)
      return this.element.src;
  }
  get reloadable() {
    return this.findFrameElement(this.element).hasAttribute("reloadable");
  }
  set reloadable(e) {
    const t = this.findFrameElement(this.element);
    e ? t.setAttribute("reloadable", "") : t.removeAttribute("reloadable");
  }
  set sourceURL(e) {
    this.settingSourceURL = !0, this.element.src = e ?? null, this.currentURL = this.element.src, this.settingSourceURL = !1;
  }
  get loadingStyle() {
    return this.element.loading;
  }
  get isLoading() {
    return this.formSubmission !== void 0 || this.resolveVisitPromise() !== void 0;
  }
  get isActive() {
    return this.element.isActive && this.connected;
  }
  get rootLocation() {
    var e;
    const t = this.element.ownerDocument.querySelector('meta[name="turbo-root"]'), s = (e = t == null ? void 0 : t.content) !== null && e !== void 0 ? e : "/";
    return S(s);
  }
}
class Zr {
  constructor(e) {
    this.visitCachedSnapshot = ({ element: t }) => {
      var s;
      const { id: i, clone: n } = this;
      (s = t.querySelector("#" + i)) === null || s === void 0 || s.replaceWith(n);
    }, this.clone = e.cloneNode(!0), this.id = e.id;
  }
}
function Le(r) {
  if (r != null) {
    const e = document.getElementById(r);
    if (e instanceof D)
      return e;
  }
}
function Me(r, e) {
  if (r) {
    const t = r.getAttribute("src");
    if (t != null && e != null && Js(t, e))
      throw new Error(`Matching <turbo-frame id="${r.id}"> element has a source URL which references itself`);
    if (r.ownerDocument !== document && (r = document.importNode(r, !0)), r instanceof D)
      return r.connectedCallback(), r.disconnectedCallback(), r;
  }
}
const Yr = {
  after() {
    this.targetElements.forEach((r) => {
      var e;
      return (e = r.parentElement) === null || e === void 0 ? void 0 : e.insertBefore(this.templateContent, r.nextSibling);
    });
  },
  append() {
    this.removeDuplicateTargetChildren(), this.targetElements.forEach((r) => r.append(this.templateContent));
  },
  before() {
    this.targetElements.forEach((r) => {
      var e;
      return (e = r.parentElement) === null || e === void 0 ? void 0 : e.insertBefore(this.templateContent, r);
    });
  },
  prepend() {
    this.removeDuplicateTargetChildren(), this.targetElements.forEach((r) => r.prepend(this.templateContent));
  },
  remove() {
    this.targetElements.forEach((r) => r.remove());
  },
  replace() {
    this.targetElements.forEach((r) => r.replaceWith(this.templateContent));
  },
  update() {
    this.targetElements.forEach((r) => {
      r.innerHTML = "", r.append(this.templateContent);
    });
  }
};
class ei extends HTMLElement {
  async connectedCallback() {
    try {
      await this.render();
    } catch (e) {
      console.error(e);
    } finally {
      this.disconnect();
    }
  }
  async render() {
    var e;
    return (e = this.renderPromise) !== null && e !== void 0 ? e : this.renderPromise = (async () => {
      this.dispatchEvent(this.beforeRenderEvent) && (await Y(), this.performAction());
    })();
  }
  disconnect() {
    try {
      this.remove();
    } catch {
    }
  }
  removeDuplicateTargetChildren() {
    this.duplicateChildren.forEach((e) => e.remove());
  }
  get duplicateChildren() {
    var e;
    const t = this.targetElements.flatMap((i) => [...i.children]).filter((i) => !!i.id), s = [...(e = this.templateContent) === null || e === void 0 ? void 0 : e.children].filter((i) => !!i.id).map((i) => i.id);
    return t.filter((i) => s.includes(i.id));
  }
  get performAction() {
    if (this.action) {
      const e = Yr[this.action];
      if (e)
        return e;
      this.raise("unknown action");
    }
    this.raise("action attribute is missing");
  }
  get targetElements() {
    if (this.target)
      return this.targetElementsById;
    if (this.targets)
      return this.targetElementsByQuery;
    this.raise("target or targets attribute is missing");
  }
  get templateContent() {
    return this.templateElement.content.cloneNode(!0);
  }
  get templateElement() {
    if (this.firstElementChild instanceof HTMLTemplateElement)
      return this.firstElementChild;
    this.raise("first child element must be a <template> element");
  }
  get action() {
    return this.getAttribute("action");
  }
  get target() {
    return this.getAttribute("target");
  }
  get targets() {
    return this.getAttribute("targets");
  }
  raise(e) {
    throw new Error(`${this.description}: ${e}`);
  }
  get description() {
    var e, t;
    return (t = ((e = this.outerHTML.match(/<[^>]+>/)) !== null && e !== void 0 ? e : [])[0]) !== null && t !== void 0 ? t : "<turbo-stream>";
  }
  get beforeRenderEvent() {
    return new CustomEvent("turbo:before-stream-render", { bubbles: !0, cancelable: !0 });
  }
  get targetElementsById() {
    var e;
    const t = (e = this.ownerDocument) === null || e === void 0 ? void 0 : e.getElementById(this.target);
    return t !== null ? [t] : [];
  }
  get targetElementsByQuery() {
    var e;
    const t = (e = this.ownerDocument) === null || e === void 0 ? void 0 : e.querySelectorAll(this.targets);
    return t.length !== 0 ? Array.prototype.slice.call(t) : [];
  }
}
D.delegateConstructor = Gr;
customElements.define("turbo-frame", D);
customElements.define("turbo-stream", ei);
(() => {
  let r = document.currentScript;
  if (r && !r.hasAttribute("data-turbo-suppress-warning")) {
    for (; r = r.parentElement; )
      if (r == document.body)
        return console.warn(_e`
        You are loading Turbo from a <script> element inside the <body> element. This is probably not what you meant to do!

        Load your application’s JavaScript bundle inside the <head> element instead. <script> elements in <body> are evaluated with each page change.

        For more information, see: https://turbo.hotwired.dev/handbook/building#working-with-script-elements

        ——
        Suppress this warning by adding a "data-turbo-suppress-warning" attribute to: %s
      `, r.outerHTML);
  }
})();
window.Turbo = Qr;
Xe();
let Ze;
async function ti() {
  return Ze || Re(si().then(Re));
}
function Re(r) {
  return Ze = r;
}
async function si() {
  const { createConsumer: r } = await import(
    /* webpackChunkName: "actioncable" */
    "./index-BhCL4V9r.js"
  );
  return r();
}
async function ri(r, e) {
  const { subscriptions: t } = await ti();
  return t.create(r, e);
}
function ce(r) {
  return !r || typeof r != "object" || r instanceof Date || r instanceof RegExp ? r : Array.isArray(r) ? r.map(ce) : Object.keys(r).reduce(function(e, t) {
    var s = t[0].toLowerCase() + t.slice(1).replace(/([A-Z]+)/g, function(i, n) {
      return "_" + n.toLowerCase();
    });
    return e[s] = ce(r[t]), e;
  }, {});
}
class ii extends HTMLElement {
  async connectedCallback() {
    Qe(this), this.subscription = await ri(this.channel, { received: this.dispatchMessageEvent.bind(this) });
  }
  disconnectedCallback() {
    Ge(this), this.subscription && this.subscription.unsubscribe();
  }
  dispatchMessageEvent(e) {
    const t = new MessageEvent("message", { data: e });
    return this.dispatchEvent(t);
  }
  get channel() {
    const e = this.getAttribute("channel"), t = this.getAttribute("signed-stream-name");
    return { channel: e, signed_stream_name: t, ...ce({ ...this.dataset }) };
  }
}
customElements.define("turbo-cable-stream-source", ii);
function ni({ detail: { formSubmission: { fetchRequest: r, submitter: e } } }) {
  e && e.formMethod && r.body.has("_method") && r.body.set("_method", e.formMethod);
}
addEventListener("turbo:submit-start", ni);
class le extends T {
  connect() {
    setTimeout(() => {
      this.close();
    }, this.timeoutValue);
  }
  close() {
    this.alertTarget.remove();
  }
}
g(le, "targets", ["alert"]), g(le, "values", {
  timeout: { type: Number, default: 1e3 }
});
class Ye extends v {
  closeModal() {
    this.wrapTarget.remove();
  }
}
g(Ye, "targets", ["wrap"]);
class et extends v {
  changeType() {
    this.inputTarget.type === "password" ? this.showPassword() : this.hidePassword();
  }
  showPassword() {
    this.inputTarget.type = "text", this.showTarget.style.display = "block", this.hideTarget.style.display = "none";
  }
  hidePassword() {
    this.inputTarget.type = "password", this.showTarget.style.display = "none", this.hideTarget.style.display = "block";
  }
}
g(et, "targets", ["input", "show", "hide"]);
class tt extends v {
  connect() {
    this.changeInput();
  }
  changeInput() {
    this.selectTarget.value === "Fixed" ? (this.fixedInputTarget.style.display = "block", this.percentageInputTarget.style.display = "none", this.percentageInputTarget.querySelector("input").value = "") : (this.fixedInputTarget.style.display = "none", this.percentageInputTarget.style.display = "block", this.fixedInputTarget.querySelector("input").value = "");
  }
}
g(tt, "targets", ["select", "fixedInput", "percentageInput"]);
class st extends v {
  connect() {
    this.changeInput();
  }
  changeInput() {
    this.selectTarget.value === "repeating" ? (this.inputTarget.classList.add("w-7/12"), this.inputTarget.classList.remove("hidden"), this.selectTarget.parentElement.parentElement.classList.remove("w-full"), this.selectTarget.parentElement.parentElement.classList.add("w-1/2")) : (this.inputTarget.classList.remove("w-7/12"), this.inputTarget.classList.add("hidden"), this.selectTarget.parentElement.parentElement.classList.add("w-full"), this.selectTarget.parentElement.parentElement.classList.remove("w-1/2"), this.inputTarget.querySelector("input").value = "");
  }
}
g(st, "targets", ["select", "input"]);
class rt extends v {
  connect() {
    document.addEventListener("click", (e) => {
      this.inputTarget.contains(e.target) || this.closeSelect();
    });
  }
  changeValue() {
    const { value: e } = this.inputTarget;
    this.data.set("value", e), this.filterItems();
  }
  openSelect() {
    this.itemsTarget.classList.remove("hidden"), this.data.set("value", ""), this.filterItems();
  }
  closeSelect() {
    this.itemsTarget.classList.add("hidden"), this.inputTarget.value = "", this.data.set("value", "");
  }
  filterItems() {
    const e = this.data.get("value").toLowerCase().trim();
    this.itemTargets.forEach((t) => {
      const s = t.dataset.title.toLowerCase();
      t.classList.toggle("hidden", s.indexOf(e) === -1);
    });
  }
  selectItem(e) {
    const t = e.target;
    this.selectItemProcess(t), this.filterItems(), this.closeSelect();
  }
  selectItemProcess(e) {
    const t = this.templateTarget.cloneNode(!0), s = ["#006aff", "#25de5d", "#ff2322", "#8e9eb3", "#7ED321", "#9a71ff", "#00ccff", "#ff3a68", "#72D7A0", "#FFC63C"];
    t.setAttribute("data-bulk-imports-assign-target", "selectedItem"), t.classList.remove("hidden"), t.querySelector("p").innerHTML = e.dataset.title, t.firstElementChild.style.backgroundColor = s[Math.floor(Math.random() * s.length)], e.dataset.image_url && (t.firstElementChild.style.backgroundImage = `url(${e.dataset.image_url})`), t.setAttribute("data-id", e.dataset.id), t.setAttribute("data-title", e.dataset.title), t.setAttribute("data-image_url", e.dataset.image_url), t.querySelector("input").value = e.dataset.id, e.remove(), this.selectedItemsTarget.appendChild(t);
  }
  deselectItem(e) {
    const t = e.target.parentElement.parentElement;
    this.addToItems(t);
  }
  deselectAllItems() {
    this.selectedItemTargets.forEach((e) => {
      this.addToItems(e);
    });
  }
  addToItems(e) {
    const t = this.createTarget("item", {
      data: {
        id: e.dataset.id,
        title: e.dataset.title,
        image_url: e.dataset.image_url,
        action: "click->bulk-imports-assign#selectItem"
      },
      htmlOptions: { class: "py-2 cursor-pointer text-sm hover:bg-gray-100" },
      innerHTML: e.dataset.title
    });
    this.itemsTarget.append(t), e.remove();
  }
}
g(rt, "targets", ["input", "template", "item", "items", "selectedItem", "selectedItems"]);
var oi = function() {
  var r = document.getSelection();
  if (!r.rangeCount)
    return function() {
    };
  for (var e = document.activeElement, t = [], s = 0; s < r.rangeCount; s++)
    t.push(r.getRangeAt(s));
  switch (e.tagName.toUpperCase()) {
    case "INPUT":
    case "TEXTAREA":
      e.blur();
      break;
    default:
      e = null;
      break;
  }
  return r.removeAllRanges(), function() {
    r.type === "Caret" && r.removeAllRanges(), r.rangeCount || t.forEach(function(i) {
      r.addRange(i);
    }), e && e.focus();
  };
};
const ai = /* @__PURE__ */ Fe(oi), ci = /mac os x/i.test(navigator.userAgent), li = `Copy to clipboard: ${ci ? "⌘" : "Ctrl"} +C, Enter`;
function it(r) {
  const e = ai(), s = document.querySelector('ds-modal[aria-expanded="true"]') || document.body;
  let i, n, o;
  try {
    if (o = document.createRange(), i = document.getSelection(), n = document.createElement("span"), n.textContent = r, n.ariaHidden = "true", n.setAttribute("style", "position: fixed; transform: translate(-10000px, -10000px);"), s.appendChild(n), o.selectNodeContents(n), i.addRange(o), !document.execCommand("copy"))
      throw new Error("copy command was unsuccessful");
  } catch {
    try {
      window.clipboardData.setData("text", r);
    } catch {
      window.prompt(li, r);
    }
  } finally {
    i && (typeof i.removeRange == "function" ? i.removeRange(o) : i.removeAllRanges()), n && s.removeChild(n), e();
  }
}
class nt extends v {
  copy(e) {
    const { value: t } = e.target.parentElement.previousElementSibling;
    it(t), this.buttonTarget.innerHTML = "Done", setTimeout(() => {
      this.buttonTarget.innerHTML = "Copy";
    }, 1e3);
  }
}
g(nt, "targets", ["button"]);
const hi = (r, e) => {
  Object.keys(e).forEach((t) => {
    r.searchParams.append(t, e[t]);
  });
};
class ot extends v {
  onInput(e) {
    const { value: t } = e.target, s = this.taggedUrlTarget, i = /^https?:\/\//i.test(t) ? t : `https://${t}`;
    if (this.resetCopyField(), !je(i)) {
      s.value = "";
      return;
    }
    try {
      const n = new URL(i), o = JSON.parse(s.dataset.utmParams);
      hi(n, o), s.value = n.toString();
    } catch {
      s.value = "";
    }
  }
  resetCopyField() {
    const e = this.taggedUrlTarget.parentElement.querySelector(".copy-link");
    e && (e.classList.remove("text-green-600"), e.classList.add("text-blue-600"), e.innerHTML = "Copy");
  }
}
g(ot, "targets", ["taggedUrl"]);
class at extends v {
  addFilename() {
    this.fileTarget.classList.remove("hidden"), this.fileNameTarget.textContent = this.inputTarget.files[0].name;
  }
}
g(at, "targets", ["input", "file", "fileName"]);
class ct extends v {
  connect() {
    document.addEventListener("input", () => {
      this.showButtonPanel();
    });
  }
  showButtonPanel() {
    this.panelTarget.classList.remove("hidden");
  }
}
g(ct, "targets", ["panel"]);
class lt extends v {
  connect() {
    this.element.addEventListener("turbo:submit-start", () => {
      this.submitButtonTarget.querySelector("svg").classList.remove("hidden");
    }), this.element.addEventListener("turbo:submit-end", () => {
      this.submitButtonTarget.querySelector("svg").classList.add("hidden");
    });
  }
}
g(lt, "targets", ["submitButton", "cancelButton"]);
class ui {
  constructor(e) {
    this.response = e;
  }
  get statusCode() {
    return this.response.status;
  }
  get redirected() {
    return this.response.redirected;
  }
  get ok() {
    return this.response.ok;
  }
  get unauthenticated() {
    return this.statusCode === 401;
  }
  get unprocessableEntity() {
    return this.statusCode === 422;
  }
  get authenticationURL() {
    return this.response.headers.get("WWW-Authenticate");
  }
  get contentType() {
    return (this.response.headers.get("Content-Type") || "").replace(/;.*$/, "");
  }
  get headers() {
    return this.response.headers;
  }
  get html() {
    return this.contentType.match(/^(application|text)\/(html|xhtml\+xml)$/) ? this.text : Promise.reject(new Error(`Expected an HTML response but got "${this.contentType}" instead`));
  }
  get json() {
    return this.contentType.match(/^application\/.*json$/) ? this.responseJson || (this.responseJson = this.response.json()) : Promise.reject(new Error(`Expected a JSON response but got "${this.contentType}" instead`));
  }
  get text() {
    return this.responseText || (this.responseText = this.response.text());
  }
  get isTurboStream() {
    return this.contentType.match(/^text\/vnd\.turbo-stream\.html/);
  }
  async renderTurboStream() {
    if (this.isTurboStream)
      window.Turbo ? await window.Turbo.renderStreamMessage(await this.text) : console.warn("You must set `window.Turbo = Turbo` to automatically process Turbo Stream events with request.js");
    else
      return Promise.reject(new Error(`Expected a Turbo Stream response but got "${this.contentType}" instead`));
  }
}
class di {
  static register(e) {
    this.interceptor = e;
  }
  static get() {
    return this.interceptor;
  }
  static reset() {
    this.interceptor = void 0;
  }
}
function mi(r) {
  const e = document.cookie ? document.cookie.split("; ") : [], t = `${encodeURIComponent(r)}=`, s = e.find((i) => i.startsWith(t));
  if (s) {
    const i = s.split("=").slice(1).join("=");
    if (i)
      return decodeURIComponent(i);
  }
}
function pi(r) {
  const e = {};
  for (const t in r) {
    const s = r[t];
    s !== void 0 && (e[t] = s);
  }
  return e;
}
function Oe(r) {
  const e = document.head.querySelector(`meta[name="${r}"]`);
  return e && e.content;
}
function fi(r) {
  return [...r].reduce((e, [t, s]) => e.concat(typeof s == "string" ? [[t, s]] : []), []);
}
function gi(r, e) {
  for (const [t, s] of e)
    s instanceof window.File || (r.has(t) ? (r.delete(t), r.set(t, s)) : r.append(t, s));
}
class bi {
  constructor(e, t, s = {}) {
    this.method = e, this.options = s, this.originalUrl = t.toString();
  }
  async perform() {
    try {
      const t = di.get();
      t && await t(this);
    } catch (t) {
      console.error(t);
    }
    const e = new ui(await window.fetch(this.url, this.fetchOptions));
    return e.unauthenticated && e.authenticationURL ? Promise.reject(window.location.href = e.authenticationURL) : (e.ok && e.isTurboStream && await e.renderTurboStream(), e);
  }
  addHeader(e, t) {
    const s = this.additionalHeaders;
    s[e] = t, this.options.headers = s;
  }
  get fetchOptions() {
    return {
      method: this.method.toUpperCase(),
      headers: this.headers,
      body: this.formattedBody,
      signal: this.signal,
      credentials: "same-origin",
      redirect: this.redirect
    };
  }
  get headers() {
    return pi(
      Object.assign(
        {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": this.csrfToken,
          "Content-Type": this.contentType,
          Accept: this.accept
        },
        this.additionalHeaders
      )
    );
  }
  get csrfToken() {
    return mi(Oe("csrf-param")) || Oe("csrf-token");
  }
  get contentType() {
    return this.options.contentType ? this.options.contentType : this.body == null || this.body instanceof window.FormData ? void 0 : this.body instanceof window.File ? this.body.type : "application/json";
  }
  get accept() {
    switch (this.responseKind) {
      case "html":
        return "text/html, application/xhtml+xml";
      case "turbo-stream":
        return "text/vnd.turbo-stream.html, text/html, application/xhtml+xml";
      case "json":
        return "application/json, application/vnd.api+json";
      default:
        return "*/*";
    }
  }
  get body() {
    return this.options.body;
  }
  get query() {
    const e = (this.originalUrl.split("?")[1] || "").split("#")[0], t = new URLSearchParams(e);
    let s = this.options.query;
    s instanceof window.FormData ? s = fi(s) : s instanceof window.URLSearchParams ? s = s.entries() : s = Object.entries(s || {}), gi(t, s);
    const i = t.toString();
    return i.length > 0 ? `?${i}` : "";
  }
  get url() {
    return this.originalUrl.split("?")[0].split("#")[0] + this.query;
  }
  get responseKind() {
    return this.options.responseKind || "html";
  }
  get signal() {
    return this.options.signal;
  }
  get redirect() {
    return this.options.redirect || "follow";
  }
  get additionalHeaders() {
    return this.options.headers || {};
  }
  get formattedBody() {
    const e = Object.prototype.toString.call(this.body) === "[object String]";
    return this.headers["Content-Type"] === "application/json" && !e ? JSON.stringify(this.body) : this.body;
  }
}
async function ht(r, e) {
  return new bi("get", r, e).perform();
}
class he extends v {
  change() {
    const e = new URLSearchParams();
    e.append(this.paramValue, this.selectTarget.value), ht(`${this.urlValue}?${e}`, {
      responseKind: "turbo-stream"
    });
  }
}
g(he, "targets", ["select"]), g(he, "values", {
  url: String,
  param: String
});
class ut extends v {
  connect() {
    this.form = this.element.closest("form"), setTimeout(() => {
      this.previousDates = this.getDates(this.inputTarget);
    }, 10);
  }
  filter() {
    this.getDates(this.inputTarget) !== this.previousDates && this.form.submit();
  }
  getDates(e) {
    return e.start + "-" + e.end;
  }
}
g(ut, "targets", ["input"]);
class dt extends T {
  connect() {
    this.checkedSelectAll = !0, this.updateSelectedApps();
  }
  updateSelectedApps() {
    const e = this.appCheckboxTargets.filter((i) => i.checked), t = e.length;
    this.setSelectedAppsIds(e), this.selectedAppsCountTarget.innerHTML = `Count = ${t}`, this.submitButtonTarget.disabled = t === 0;
    const s = t === 1 ? "store" : "stores";
    this.submitButtonTarget.dataset.confirm = `Are you sure you want to update ${t} ${s}?`;
  }
  setSelectedAppsIds(e) {
    const t = e.map((s) => s.value);
    this.sortButtonTargets.forEach((s) => {
      const i = new URL(s.href);
      i.searchParams.set("app_ids", t), s.href = i.toString();
    });
  }
  toggleSelectAll(e) {
    e.preventDefault(), this.checkedSelectAll = !this.checkedSelectAll, e.target.innerHTML = this.checkedSelectAll ? "Unselect All" : "Select All", this.appCheckboxTargets.forEach((t) => {
      t.checked = this.checkedSelectAll;
    }), this.updateSelectedApps();
  }
}
g(dt, "targets", ["selectedAppsCount", "appCheckbox", "submitButton", "sortButton"]);
class mt extends T {
  connect() {
    this.checkedSelectAll = !0, this.updateSelectedStores();
  }
  updateSelectedStores() {
    const t = this.storeCheckboxTargets.filter((i) => i.checked).length;
    this.selectedStoresCountTarget.innerHTML = `Count = ${t}`, this.submitButtonTarget.disabled = t === 0;
    const s = t === 1 ? "store" : "stores";
    this.submitButtonTarget.dataset.confirm = `Are you sure want to request apple agreements for ${t} ${s}?`;
  }
  toggleSelectAll(e) {
    e.preventDefault(), this.checkedSelectAll = !this.checkedSelectAll, e.target.innerHTML = this.checkedSelectAll ? "Unselect All" : "Select All", this.storeCheckboxTargets.forEach((t) => {
      t.checked = this.checkedSelectAll;
    }), this.updateSelectedStores();
  }
}
g(mt, "targets", ["selectedStoresCount", "storeCheckbox", "submitButton"]);
class pt extends v {
  connect() {
    this.form = this.element.closest("form"), setTimeout(() => {
      this.previousValue = this.getValue(this.inputTarget);
    }, 10);
  }
  submit() {
    this.getValue(this.inputTarget) !== this.previousValue && this.form.submit();
  }
  getValue(e) {
    return e.value.sort().join(",");
  }
}
g(pt, "targets", ["input"]);
class vi extends v {
  submit() {
    const e = this.element.dataset.url, t = new URLSearchParams();
    t.append(this.element.name, this.element.value), ht(`${e}?${t}`, {
      responseKind: "turbo-stream"
    });
  }
}
class yi extends T {
  clear() {
    setTimeout(() => {
      this.element.closest("form").submit();
    }, 10);
  }
}
class wi extends T {
  search(e) {
    const t = e.currentTarget;
    setTimeout(() => {
      t.closest("form").submit();
    }, 100);
  }
  searchDate(e) {
    const t = e.currentTarget;
    (t.start && t.end || !t.start && !t.end) && setTimeout(() => {
      t.closest("form").submit();
    }, 100);
  }
}
class ft extends T {
  setBranchParam() {
    const e = this.branchInputTarget.value;
    this.urlTargets.forEach((t) => {
      const s = new URL(t.href);
      e ? s.searchParams.set("branch", e) : s.searchParams.delete("branch"), t.href = s.toString();
    });
  }
  setBranchInput(e) {
    this.branchInputTarget.value = e.target.textContent, this.setBranchParam();
  }
}
g(ft, "targets", ["url", "branchInput"]);
class gt extends T {
  connect() {
    this.checkboxTarget.checked || this.toggleAppBody();
  }
  toggleAppBody() {
    console.log("this.hasAppBodyTarget", this.hasAppBodyTarget), this.hasAppBodyTarget && this.appBodyTarget.classList.toggle("hidden");
  }
}
g(gt, "targets", ["appBody", "checkbox"]);
class bt extends T {
  connect() {
    const e = Number(this.originalPriceFieldTarget.value);
    this.drawerPriceFieldTarget.value = e;
  }
  openDrawer() {
    this.drawerTarget.open();
  }
  closeDrawer() {
    this.drawerTarget.close();
  }
  handlePriceChange() {
    this.originalPriceFieldTarget.value = Number(this.drawerPriceFieldTarget.value);
  }
}
g(bt, "targets", [
  "drawer",
  "originalPriceField",
  "drawerPriceField"
]);
var N, U;
class vt extends T {
  constructor() {
    super(...arguments);
    ye(this, N);
  }
  connect() {
    this.checkOwnerRelatedRoles(), this.checkPublisherRelatedRoles(), this.checkContentManagerRelatedRoles();
  }
  checkOwnerRelatedRoles() {
    V(this, N, U).call(this, "user[roles][owner]").checked && this.roleCheckboxTargets.forEach((s) => {
      s.checked = !0, s.disabled = !0;
    });
  }
  checkPublisherRelatedRoles() {
    const t = V(this, N, U).call(this, "user[roles][publisher]");
    this.roleCheckboxTargets.forEach((s) => {
      [t.name, "user[roles][owner]"].includes(s.name) || (s.checked = t.checked || s.dataset.initialCheck === "true", s.disabled = t.checked);
    });
  }
  checkContentManagerRelatedRoles() {
    const t = V(this, N, U).call(this, "user[roles][content_manager]"), s = V(this, N, U).call(this, "user[roles][video_upload_manager]");
    s.checked = t.checked || s.dataset.initialCheck === "true", s.disabled = t.checked;
  }
}
N = new WeakSet(), U = function(t) {
  return this.roleCheckboxTargets.find((s) => s.name === t);
}, g(vt, "targets", ["roleCheckbox"]);
class yt extends T {
  showManagePasswordsModal() {
    this.createModal(this.managePasswordsModalTemplateTarget.innerHTML, "manage_passwords_modal", "small", !0);
  }
  copyPasswordResetLink(e) {
    e.preventDefault();
    const t = document.getElementById("modal_flash_message"), s = document.createElement("div");
    s.classList.add("u-flash-modal", "u-flash-modalsuccess"), s.setAttribute("data-controller", "alert"), s.setAttribute("data-alert-timeout-value", "5000"), s.setAttribute("data-action", "click->alert#close"), s.setAttribute("data-alert-target", "alert"), s.innerText = "Password reset link has been copied to your clipboard.";
    const { currentTarget: i } = e;
    i.setAttribute("loading", "true"), fetch(i.dataset.url).then((n) => n.json()).then((n) => {
      i.removeAttribute("loading"), t.appendChild(s), it(n.link);
    });
  }
  sendPasswordResetLink(e) {
    e.preventDefault();
    const t = document.getElementById("modal_flash_message"), s = document.createElement("div");
    s.classList.add("u-flash-modal", "u-flash-modalsuccess"), s.setAttribute("data-controller", "alert"), s.setAttribute("data-alert-timeout-value", "5000"), s.setAttribute("data-action", "click->alert#close"), s.setAttribute("data-alert-target", "alert"), s.innerText = "Password reset link has been sent.";
    const { currentTarget: i } = e;
    i.setAttribute("loading", "true"), fetch(i.dataset.url).then((n) => n.json()).then(() => {
      i.removeAttribute("loading"), t.appendChild(s);
    });
  }
  createModal(e, t, s = "large", i = !1) {
    const n = document.getElementById(t);
    n && n.remove();
    const o = document.createElement("ds-modal");
    return o.setAttribute("size", s), o.setAttribute("position", "center"), o.setAttribute("closable", "true"), o.setAttribute("show-on-mount", "true"), o.setAttribute("id", t), i && o.setAttribute("with-close-icon", "true"), o.innerHTML = e, this.element.appendChild(o), o;
  }
  closeModal(e) {
    e.currentTarget.closest("ds-modal").close();
  }
}
g(yt, "targets", ["managePasswordsModalTemplate"]);
try {
  Rt.start();
} catch (r) {
  console.log(r.message);
}
class Ei extends v {
  /* eslint-disable class-methods-use-this */
  export(e) {
    const t = e.target, s = t.options[t.selectedIndex].innerHTML;
    je(t.value) && $s(t.value, s);
  }
}
class wt extends v {
  update_utm_media_select() {
    const e = this.utmCategoryTarget, s = JSON.parse(e.dataset.utmMediumValues)[e.value], i = document.getElementById("utm_tag_utm_medium");
    i.innerHTML = "", s.forEach((n) => {
      const o = document.createElement("option");
      o.text = n, o.value = n, i.add(o);
    });
  }
}
g(wt, "targets", ["utmCategory"]);
class Et extends v {
  initialize() {
    this.updatePreview = this.updatePreview.bind(this);
  }
  connect() {
    this.inputTarget.addEventListener("change", this.updatePreview);
  }
  updatePreview() {
    const e = new FileReader();
    this.fileRemoveTarget.value = "false", e.onload = (t) => {
      this.previewTargets.forEach((s) => {
        s.tagName !== "IMG" && (s.style.backgroundImage = `url(${t.target.result})`);
      });
    }, e.readAsDataURL(this.inputTarget.files[0]);
  }
  removePreview() {
    this.fileRemoveTarget.value = "true", this.inputTarget.value = "", this.previewTargets.forEach((e) => {
      e.style.backgroundImage = "none", e.tagName === "IMG" && (e.src = "", e.style.display = "none");
    });
  }
}
g(Et, "targets", ["input", "preview", "fileRemove"]);
class St extends v {
  initialize() {
  }
  connect() {
    this.individualAccountSwitcherTarget.checked ? this.toggleDisplay(this.teamAccountContainerTarget) : this.toggleDisplay(this.individualAccountContainerTarget);
  }
  insertAppId(e) {
    e.preventDefault(), this.appInputTarget.value = e.target.dataset.id, e.target.dataset.iaps && (this.iapInputTarget.value = e.target.dataset.iaps), e.target.dataset.name && (this.nameInputTarget.value = e.target.dataset.name);
  }
  insertIapId(e) {
    e.preventDefault(), this.iapInputTarget.value = e.target.dataset.id;
  }
  // eslint-disable-next-line class-methods-use-this
  toggleDisplay(e) {
    e.style.display === "none" ? e.style.display = "block" : e.style.display = "none";
  }
  toggleAccountTypes() {
    this.toggleDisplay(this.teamAccountContainerTarget), this.toggleDisplay(this.individualAccountContainerTarget);
  }
}
g(St, "targets", [
  "appList",
  "appInput",
  "iapList",
  "iapInput",
  "nameInput",
  "individualAccountSwitcher",
  "teamAccountContainer",
  "individualAccountContainer"
]);
window.Turbo.session.drive = !1;
const f = As.start();
f.register("export-csv", Ei);
f.register("modal", Ye);
f.register("password-input", et);
f.register(
  "bulk-imports-discount-amount",
  tt
);
f.register(
  "bulk-imports-coupon-duration",
  st
);
f.register("bulk-imports-assign", rt);
f.register("utm-link", ot);
f.register("upload-button", at);
f.register("button-panel", ct);
f.register("utm-tags", wt);
f.register("clipboard", nt);
f.register("store-form", lt);
f.register("image-preview", Et);
f.register("app-form", St);
f.register("select", he);
f.register("alert", le);
f.register("bulk-update-form", dt);
f.register(
  "bulk-apple-agreements-request-form",
  mt
);
f.register("datepicker", ut);
f.register("multiselect", pt);
f.register("radio-button", vi);
f.register("users-search", yi);
f.register("newsfeed", wi);
f.register("ott-builder-danger-zone", ft);
f.register("toggle-app-body", gt);
f.register(
  "subscription-plans--ott-price-change",
  bt
);
f.register("user-roles", vt);
f.register("user-edit", yt);
